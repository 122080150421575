export const LS_KtmV3EventDataList = [
  {
    label: "Basic Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Event ID",
        name: "EventID",
        type: "number",
      },
      {
        label: "EVC",
        name: "EVC",
        type: "number",
      },
      {
        label: "Country",
        name: "CountryCohort",
        type: "text",
      },
      {
        label: "Payer",
        name: "PayerCohort",
        type: "select",
        values: [0, 1, 2],
        valueLabels: ["No Payer", "Payer", "Both"],
      },
      {
        label: "Created After",
        name: "CreatedAfterCohort",
        type: "time",
      },
      {
        label: "Start Date",
        name: "StartDate",
        type: "time",
      },
      {
        label: "End Date",
        name: "EndDate",
        type: "time",
      },
      {
        label: "Min Client Version",
        name: "MinClientVersion",
        type: "number",
      },
      {
        label: "Monster HP",
        name: "MonsterHp[0]",
        type: "number",
      },
      {
        label: "Doll Type",
        name: "DollType[0]",
        type: "number",
      },
      {
        label: "Main Reward Name",
        name: "MainRewardName",
        type: "text",
        serverType: "text",
      },
    ],
  },
  {
    label: "Test Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Start Date",
        name: "TestDate.start_date",
        type: "time",
      },
      {
        label: "End Date",
        name: "TestDate.end_date",
        type: "time",
      },
    ],
  },
  {
    label: "Rewards",
    name: "Rewards[2]",
    type: "group",
    data: [
      {
        label: "Coins",
        name: "G",
        type: "number",
      },
      {
        label: "Potions",
        name: "P",
        type: "number",
      },
      {
        label: "Dice ID",
        name: "D",
        type: "number",
      },
      {
        label: "Booster",
        name: "B",
        type: "number",
      },
      {
        label: "Token",
        name: "TID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Board",
        name: "BID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Avatar",
        name: "AID",
        type: "text",
        serverType: "array",
      },
    ],
  },
  {
    label: "Asset Details",
    name: "EventThemeInfo",
    type: "group",
    data: [
      {
        label: "Event Type",
        name: "Name",
        type: "select",
        values: [
          "aliens",
          "beat_the_heat",
          "boredom",
          "cyclops",
          "dino",
          "fix_the_car",
          "fly_the_ballon",
          "medusa",
          "minotaur",
          "paintball",
          "pinata",
          "samurai",
          "stop_the_robbery",
          "valentines_day",
          "werewolf",
        ],
        valueLabels: [
          "Aliens",
          "Beat The Heat",
          "Boredom",
          "Cyclops",
          "Dino",
          "Fix The Car",
          "Fly The Ballon",
          "Medusa",
          "Minotaur",
          "Paintball",
          "Pinata",
          "Samurai",
          "Stop The Robbery",
          "Valentines Day",
          "Warewolf",
        ],
      },
      {
        label: "Use default assets",
        name: "DefaultAsset",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
];

export const LS_TreasureRoadEventDataList = [
  {
    label: "Basic Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Event ID",
        name: "EventID",
        type: "number",
      },
      {
        label: "EVC",
        name: "EVC",
        type: "number",
      },
      {
        label: "Country",
        name: "CountryCohort",
        type: "text",
      },
      {
        label: "Payer",
        name: "PayerCohort",
        type: "select",
        values: [0, 1, 2],
        valueLabels: ["No Payer", "Payer", "Both"],
      },
      {
        label: "Min Client Version",
        name: "MinClientVersion",
        type: "number",
      },
      {
        label: "Created After",
        name: "CreatedAfterCohort",
        type: "time",
      },
      {
        label: "Start Date",
        name: "StartDate",
        type: "time",
      },
      {
        label: "End Date",
        name: "EndDate",
        type: "time",
      },
      {
        label: "Pumpkins/Dice",
        name: "MonsterHp[0]",
        type: "number",
      },
      {
        label: "Doll Type",
        name: "DollType[0]",
        type: "number",
      },
    ],
  },
  {
    label: "Test Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Start Date",
        name: "TestDate.StartDate",
        type: "time",
      },
      {
        label: "End Date",
        name: "TestDate.EndDate",
        type: "time",
      },
    ],
  },
  {
    label: "Rewards",
    name: "Rewards[0]",
    type: "group",
    data: [
      {
        label: "Coins",
        name: "G",
        type: "number",
      },
      {
        label: "Potions",
        name: "P",
        type: "number",
      },
      {
        label: "Dice ID",
        name: "D",
        type: "number",
      },
      {
        label: "Booster",
        name: "B",
        type: "number",
      },
      {
        label: "Token",
        name: "TID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Board",
        name: "BID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Avatar",
        name: "AID",
        type: "text",
        serverType: "array",
      },
      {
        label: "Has Card Reward",
        name: "HCR",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
  {
    label: "Treasure Rewards",
    name: "TreasureRoadRewards",
    type: "treasure-reward",
    data: [],
  },
  {
    label: "Asset Details",
    name: "EventThemeInfo",
    type: "group",
    data: [
      {
        label: "Event Type",
        name: "Name",
        type: "select",
        values: [
          "delicious_desserts",
          "mad_scientist",
          "salsa",
          "sherlock",
          "space",
          "tarzan",
          "toy",
        ],
        valueLabels: [
          "Delicious Desserts",
          "Mad Scientist",
          "Salsa",
          "Sherlock",
          "Space",
          "Tarzan",
          "Toy",
        ],
      },
      {
        label: "Use default assets",
        name: "DefaultAsset",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
];

export const LS_KtmV4EventDataList = [
  {
    label: "Basic Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Event ID",
        name: "EventID",
        type: "number",
      },
      {
        label: "EVC",
        name: "EVC",
        type: "number",
      },
      {
        label: "Country",
        name: "CountryCohort",
        type: "text",
      },
      {
        label: "Payer",
        name: "PayerCohort",
        type: "select",
        values: [0, 1, 2],
        valueLabels: ["No Payer", "Payer", "Both"],
      },
      {
        label: "Created After",
        name: "CreatedAfterCohort",
        type: "time",
      },
      {
        label: "Start Date",
        name: "StartDate",
        type: "time",
      },
      {
        label: "End Date",
        name: "EndDate",
        type: "time",
      },
      {
        label: "Min Client Version",
        name: "MinClientVersion",
        type: "number",
      },
      {
        label: "Monster HP",
        name: "MonsterHp[0]",
        type: "number",
      },
      {
        label: "Type",
        name: "EventRewardType",
        type: "select",
        values: [0, 1, 2, 3, 4, 5, 6],
        valueLabels: [
          "None",
          "Kill",
          "Goal",
          "Kill + Goal",
          "Win",
          "Kill + Win",
          "Goal + Win",
        ],
      },
      {
        label: "Doll Type",
        name: "DollType[0]",
        type: "number",
      },
    ],
  },
  {
    label: "Test Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Start Date",
        name: "TestDate.start_date",
        type: "time",
      },
      {
        label: "End Date",
        name: "TestDate.end_date",
        type: "time",
      },
    ],
  },
  {
    label: "Free Tier 8",
    name: "Rewards[0]",
    type: "group",
    data: [
      {
        label: "Coins",
        name: "G",
        type: "number",
      },
      {
        label: "Potions",
        name: "P",
        type: "number",
      },
      {
        label: "Dice ID",
        name: "D",
        type: "number",
      },
      {
        label: "Booster",
        name: "B",
        type: "number",
      },
      {
        label: "Token",
        name: "TID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Board",
        name: "BID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Avatar",
        name: "AID",
        type: "text",
        serverType: "array",
      },
      {
        label: "Has Card Reward",
        name: "HCR",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
  {
    label: "Bonus Tier 8",
    name: "Rewards[1]",
    type: "group",
    data: [
      {
        label: "Coins",
        name: "G",
        type: "number",
      },
      {
        label: "Potions",
        name: "P",
        type: "number",
      },
      {
        label: "Dice ID",
        name: "D",
        type: "number",
      },
      {
        label: "Booster",
        name: "B",
        type: "number",
      },
      {
        label: "Token",
        name: "TID[0]",
        type: "number",
      },
      {
        label: "Board",
        name: "BID[0]",
        type: "number",
      },
      {
        label: "Avatar",
        name: "AID[0]",
        type: "text",
      },
      {
        label: "Has Card Reward",
        name: "HCR",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
  {
    label: "Asset Details",
    name: "EventThemeInfo",
    type: "group",
    data: [
      {
        label: "Event Type",
        name: "Name",
        type: "select",
        values: ["villain"],
        valueLabels: ["Defeat The Villain"],
      },
      {
        label: "Use default assets",
        name: "DefaultAsset",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
];
