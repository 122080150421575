import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import {
  Container,
  FormControl,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from "@mui/material";
import { PropTypes } from "prop-types";
import { useSnackbar } from "notistack";
import Wrapper from "../components/Wrapper";
import axios from "axios";
import ClubDpsUploader from "../components/ClubDpsUploader";
import { playEventCompletionSound } from "../utils/sound";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const ClubDpsUpload = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [currentServer, setCurrentServer] = useState(0);
  const [clubDpInfos, setClubDpInfos] = useState([]);
  const [dpUploaders, setDpUploaders] = useState([]);
  const [addDpEnabled, setAddDpEnabled] = useState(false);

  const handleServerChange = (event) => {
    setCurrentServer(event.target.value);
  };

  const setEnableAddDp = (isEnabled) => {
    setAddDpEnabled(isEnabled);
  };

  useEffect(() => {
    if (dpUploaders.length == 0) {
      var dpUploader = [];
      dpUploader.push(
        <ClubDpsUploader props={props} setEnableAddDp={setEnableAddDp} />
      );
      setAddDpEnabled(false);
      setDpUploaders(dpUploader);
    }
  }, []);

  const publishDp = () => {
    if (!addDpEnabled) {
      const key = enqueueSnackbar("Enter all details", {
        variant: "error",
        persist: true,
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return;
    }

    setLoading(true);
    let url = "/api/uploadClubDp/dev/" + currentServer;
    axios
      .post(url, clubDpInfos)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Club Dp Publish Failed. " + data.err, {
            variant: "error",
            persist: true,
            onClick: () => {
              closeSnackbar(key);
            },
          });
          playEventCompletionSound(false);
        } else {
          const key = enqueueSnackbar("Club Dp Publish Successful", {
            variant: "success",
            persist: true,
            onClick: () => {
              closeSnackbar(key);
            },
          });
          playEventCompletionSound(true);
        }
        setLoading(false);
        setDpUploaders([]);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Club Dp Publish Failed. " + err, {
          variant: "error",
          persist: true,
          onClick: () => {
            closeSnackbar(key);
          },
        });
        playEventCompletionSound(false);
        setLoading(false);
      });
    setLoading(true);
  };

  const addDps = () => {
    var dpUploader = [];
    dpUploader = [
      ...dpUploaders,
      <ClubDpsUploader props={props} setEnableAddDp={setEnableAddDp} />,
    ];
    setDpUploaders(dpUploader);
    setAddDpEnabled(false);
  };

  return (
    <div className={props.root}>
      <Wrapper heading="Club Dp Upload" setIsLoggedIn={props.setIsLoggedIn}>
        <Container maxWidth="lg" className={props.container}>
          <div className={props.header}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={addDps}
              disabled={!addDpEnabled || loading}
            >
              Add more club dps
              {loading && (
                <CircularProgress size={24} className={props.buttonProgress} />
              )}
            </Button>
            <div style={{ flexGrow: 1 }}></div>
            <FormControl variant="outlined" className={props.formControl}>
              <Select
                labelId="server-select"
                id="server-select"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={currentServer}
                onChange={handleServerChange}
              >
                <MenuItem value={0}>Dev</MenuItem>
                <MenuItem value={1}>Production</MenuItem>
              </Select>
            </FormControl>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={publishDp}
              disabled={loading}
            >
              Publish
              {loading && (
                <CircularProgress size={24} className={props.buttonProgress} />
              )}
            </Button>
          </div>
        </Container>
        {dpUploaders}
      </Wrapper>
    </div>
  );
};

ClubDpsUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(ClubDpsUpload, useStyles);
