import React from "react";
import ReactDOM from "react-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en-in"; // required to set DayJs locale in LocalizationProvider

import App from "./App";
import theme from "./Theme";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-in"}>
      <CssBaseline />
      <App />
    </LocalizationProvider>
  </ThemeProvider>,
  document.querySelector("#root"),
);

if (module.hot) {
  module.hot.accept();
}
