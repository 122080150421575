import { Grid, Paper, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";

import InputBox from "./InputBox";
import { get as lodashGet } from "lodash";

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  header: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
}));

export default function InputGroup(props) {
  const { classes } = useStyles();

  const getInputList = () => {
    return props.option.data.map((option, index) => {
      return (
        <Grid key={index} item sm={6} lg={4}>
          <InputBox
            option={option}
            value={lodashGet(props.value, option.name)}
            handleChange={(name, value, serverType) => {
              var add = "";
              if (props.option.name != "") {
                add = ".";
              }
              props.handleChange(
                props.option.name + add + name,
                value,
                serverType
              );
            }}
          />
        </Grid>
      );
    });
  };

  return (
    <Paper className={classes.paper}>
      <Grid container className={classes.container}>
        <Grid item sm={12} lg={12} className={classes.header}>
          <Typography variant="h6">{props.option.label}</Typography>
        </Grid>
        {getInputList()}
      </Grid>
    </Paper>
  );
}

InputGroup.propTypes = {
  children: PropTypes.node,
};
