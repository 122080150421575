import { create } from "zustand";

interface State {
  user: {};
  eventDrafts: {};
  currentView: "live";
  currentLiveServer: number;
  userLoading: boolean;
  liveLoading: boolean;
  cohortLoading: boolean;
  eventLoading: boolean;
  liveInfo: {};
  eventDiceID: "0";
  cosmeticDiceID: "0";
  rewardBoxDiceID: "0";
  diceID: "0";
  tokenID: "0";
  frameID: "0";
  avatarID: "0";
  nextVersion: 0;
  cohortData: {};
}

export const useDashboardStore = create<State>((set) => ({
  user: {},
  eventDrafts: {},
  currentView: "live",
  currentLiveServer: 2,
  userLoading: false,
  liveLoading: false,
  cohortLoading: true,
  eventLoading: true,
  liveInfo: {},
  eventDiceID: "0",
  cosmeticDiceID: "0",
  rewardBoxDiceID: "0",
  diceID: "0",
  tokenID: "0",
  frameID: "0",
  avatarID: "0",
  nextVersion: 0,
  cohortData: {},
}));
