import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import AddCircle from "@mui/icons-material/AddCircle";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import {
  Paper,
  Grid,
  Typography,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  subPaper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  header: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
}));

var options = [
  { value: 0, label: "Gold" },
  { value: 1, label: "Gems" },
  { value: 4, label: "Booster" },
  { value: 13, label: "Platinum Coins" },
  { value: 14, label: "Gold Spanner" },
  { value: 15, label: "Rainbow Ink" },
  { value: 16, label: "Category Coins" },
];

var subOptions = [
  { value: 0, label: "None" },
  { value: 1, label: "Animals" },
  { value: 2, label: "Characters" },
  { value: 3, label: "Food" },
  { value: 4, label: "Sports" },
  { value: 5, label: "Vehicles" },
  { value: 6, label: "Others" },
];

var labelMap = {
  0: "Gold",
  1: "Gems",
  4: "Booster",
  13: "Platinum Coins",
  14: "Gold Spanner",
  15: "Rainbow Ink",
  16: "Category Coins",
};

var optionsLabelMap = {
  0: "None",
  1: "Animals",
  2: "Characters",
  3: "Food",
  4: "Sports",
  5: "Vehicles",
  6: "Others",
};

var ValueMap = {
  Gold: 0,
  Gems: 1,
  Booster: 4,
  "Platinum Coins": 13,
  "Gold Spanner": 14,
  "Rainbow Ink": 15,
  "Category Coins": 16,
};

var optionsValueMap = {
  None: 0,
  Animals: 1,
  Characters: 2,
  Food: 3,
  Sports: 4,
  Vehicles: 5,
  Others: 6,
};

export default function TreasureReward(props) {
  const { classes } = useStyles();

  const getInputList = () => {
    return props.value.map((reward, index) => {
      return (
        <Grid key={index} item sm={12} lg={12}>
          <Paper
            className={classes.subPaper}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item sm={3} lg={3}>
              <TextField
                className={classes.textField}
                id="textfield-number"
                type="number"
                label={"Tile Number"}
                onChange={(event) => {
                  props.handleChange(
                    event.target.name + "[" + index + "].Tile",
                    parseInt(event.target.value)
                  );
                }}
                value={reward.Tile}
                name={props.option.name}
              />
            </Grid>
            <Grid item sm={3} lg={3}>
              <FormControl className={classes.formControl}>
                <InputLabel id="select-id">{"Reward Type"}</InputLabel>
                <Select
                  labelId="select-id"
                  id="select"
                  value={labelMap[reward.Type]}
                  name={props.option.name}
                  onChange={(event) => {
                    props.handleChange(
                      event.target.name + "[" + index + "].Type",
                      ValueMap[event.target.value]
                    );
                  }}
                  label={"Reward Type"}
                >
                  {options.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option.label}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={3} lg={3}>
              <FormControl className={classes.formControl}>
                <InputLabel id="select-id">{"SubCategory"}</InputLabel>
                <Select
                  labelId="select-id"
                  id="select"
                  value={optionsLabelMap[reward.SubCategory]}
                  name={props.option.name}
                  onChange={(event) => {
                    props.handleChange(
                      event.target.name + "[" + index + "].SubCategory",
                      optionsValueMap[event.target.value]
                    );
                  }}
                  label={"SubCategory"}
                >
                  {subOptions.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option.label}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={3} lg={3}>
              <TextField
                className={classes.textField}
                id="textfield-number"
                type="number"
                label={"Count"}
                onChange={(event) => {
                  props.handleChange(
                    event.target.name + "[" + index + "].Count",
                    parseInt(event.target.value)
                  );
                }}
                value={reward.Count}
                name={props.option.name}
              />
            </Grid>
          </Paper>
        </Grid>
      );
    });
  };

  return (
    <Paper className={classes.paper}>
      <Grid container className={classes.container}>
        <Typography variant="h6">{props.option.label}</Typography>
        <div style={{ flexGrow: 1 }}></div>
        <IconButton
          onClick={() => {
            props.addTreasureReward(1);
          }}
        >
          <AddCircle color="primary" />
        </IconButton>
        <IconButton
          onClick={() => {
            props.addTreasureReward(-1);
          }}
        >
          <RemoveCircle color="primary" />
        </IconButton>
        {getInputList()}
      </Grid>
    </Paper>
  );
}

TreasureReward.propTypes = {
  children: PropTypes.node,
};
