import * as dotenv from "dotenv";

export const enum AppType {
  Parchisi = 0,
  Ludo = 1,
}

export const enum EnvironmentType {
  Local = 0,
  Development = 1,
  Staging = 2,
  Production = 3,
}

export const GetAppType = (): AppType => {
  switch (process.env.REACT_APP_APPTYPE) {
    case "0":
      return AppType.Parchisi;
    case "1":
      return AppType.Ludo;

    default:
      return AppType.Parchisi;
  }
};

export const GetEnvType = (): EnvironmentType => {
  switch (process.env.REACT_APP_ENV) {
    case "0":
      return EnvironmentType.Local;
    case "1":
      return EnvironmentType.Development;
    case "2":
      return EnvironmentType.Staging;
    case "3":
      return EnvironmentType.Production;

    default:
      return EnvironmentType.Local;
  }
};

export class Environment {
  static appType = GetAppType();
  static env = GetEnvType();
}
