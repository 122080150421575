import React, { useState, useEffect } from "react";
import Wrapper from "../components/Wrapper";
import { withStyles } from "tss-react/mui";
import {
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { PropTypes } from "prop-types";
import DropBox from "../components/DropBox";
import CountrySelector from "../common/CountrySelector";
import LanguageSelector from "../common/LanguageSelector";
import GetToolTipLine from "../common/TooltipLine";
import InfoIcon from "@mui/icons-material/Info";
import { EnvVars } from "../common/environment";
import { playEventCompletionSound } from "../utils/sound";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    margin: "5vh 10vw",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const MarketingBannerUpload = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [themeID, setThemeID] = useState(0);
  const [loading, setLoading] = useState(false);
  const [server, setServer] = useState(0);
  const [mainFiles, setMainFiles] = useState([]);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [link, setLink] = useState("");
  const [languages, setLanguages] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getThemeID();
  }, []);

  const getThemeID = () => {
    setLoading(true);
    axios
      .get("/api/marketingBanner/getThemeID")
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        setLoading(false);
        if (data.payload) {
          setThemeID(data["payload"]);
        }
      });
  };

  const checkValidity = (data) => {
    for (let i in data) {
      if (Object.keys(data[i]).length == 0) {
        return false;
      }
    }
    if (
      themeID <= 0 ||
      mainFiles.length <= 0 ||
      startTime == Date() ||
      endTime == Date() ||
      link == "" ||
      languages.length <= 0 ||
      countries.length <= 0
    ) {
      return false;
    }
    return true;
  };

  const publishBanner = (data) => {
    let isDataValid = checkValidity(data);
    if (!isDataValid) {
      alert("Invalid or Missing Data");
      return;
    }
    setLoading(true);
    console.log("reward: ", themeID);
    let url = `/api/publishMarketingBanner/dev/${server}/theme/${themeID}`;
    let cosmeticData = {
      themeID: themeID,
      startDate: startTime,
      endDate: endTime,
      link: link,
      eligible_language: languages,
      eligible_country: countries,
    };
    axios
      .post(url, cosmeticData)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Publish Failed", {
            variant: "error",
            autoHideDuration: 3000,
            maxSnack: 4,
          });
          playEventCompletionSound(false);
        } else {
          const key = enqueueSnackbar("Publish Successful", {
            variant: "success",
          });
          playEventCompletionSound(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Publish Failed", {
          variant: "error",
          autoHideDuration: 3000,
          maxSnack: 4,
        });
        playEventCompletionSound(false);
        setLoading(false);
      });
  };

  // Uploads bundle assets to the tool instance
  const uploadBannerAssets = (file, type) => {
    setLoading(true);
    const data = new FormData();
    data.append("file", file);
    let url = `/api/file/marketingBannerAssets/${themeID}/${type}`;
    axios
      .post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
            variant: "error",
            autoHideDuration: 3000,
            maxSnack: 4,
          });
        } else {
          const key = enqueueSnackbar("File - " + file.name + " uploaded", {
            variant: "success",
            autoHideDuration: 3000,
            maxSnack: 4,
          });
          if (type == "main") {
            setMainFiles((current) => [...current, data]);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
          variant: "error",
          autoHideDuration: 3000,
          maxSnack: 4,
        });
        setLoading(false);
      });
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadBannerAssets(file, type);
    }
  };

  const { classes } = props;

  return (
    <div className={classes.root}>
      <Wrapper
        heading="Marketing Banner Upload"
        setIsLoggedIn={props.setIsLoggedIn}
      >
        <div style={{ margin: "5vh 10vw" }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={3}>
              <TextField
                id="outlined-basic"
                label="Theme ID"
                value={themeID}
                onChange={(event) => {
                  setThemeID(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              {EnvVars.EnvSelectorDisabled ? (
                <></>
              ) : (
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    labelId="server-select"
                    id="server-select"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={server}
                    onChange={(event) => {
                      setServer(event.target.value);
                    }}
                  >
                    <MenuItem value={0}>Dev</MenuItem>
                    <MenuItem value={1}>Production</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} sm={3} />
            <Grid item xs={12} sm={3}>
              <Button
                onClick={() => {
                  publishBanner();
                }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="outlined"
                size="large"
                disabled={loading}
              >
                Publish
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label={"Start Date"}
                type="datetime-local"
                value={startTime}
                className={classes.textField}
                onChange={(event) => {
                  setStartTime(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label={"End Date"}
                type="datetime-local"
                value={endTime}
                className={classes.textField}
                onChange={(event) => {
                  setEndTime(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} />
            <Grid item xs={12} sm={3} />
            <Grid item xs={12} sm={3}>
              <TextField
                label={"Link"}
                type="url"
                value={link}
                className={classes.textField}
                onChange={(event) => {
                  setLink(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <div>
                <CountrySelector
                  countries={countries}
                  setCountries={setCountries}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={4}>
              <div>
                <LanguageSelector
                  languages={languages}
                  setLanguages={setLanguages}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={8} />

            <Grid item xs={12}>
              <h2>
                Banner Assets:
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        Banner Upload Info
                      </Typography>
                      <GetToolTipLine left="Required:" right="Market" />
                    </React.Fragment>
                  }
                >
                  <InfoIcon sx={{ fontSize: 30 }} />
                </Tooltip>
              </h2>

              <DropBox
                type="main"
                uploadedFiles={mainFiles}
                handleDropFile={handleDropFile}
                loading={loading}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>
        </div>
      </Wrapper>
    </div>
  );
};

MarketingBannerUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(MarketingBannerUpload, useStyles);
