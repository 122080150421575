import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Container, TextField } from "@mui/material";
import DropBox from "./DropBox";
import axios from "axios";
import { useSnackbar } from "notistack";
import { withStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const FrameUploader = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { classes } = useStyles();

  const [files, setFiles] = useState([]);
  const [frameID, setFrameID] = useState(props.frameID);
  const [frameName, setFrameName] = useState("");
  const [loading, setLoading] = useState(false);

  const uploadFile = (file, type) => {
    if (frameID == "") {
      const key = enqueueSnackbar("No Frame ID", {
        variant: "error",
      });
      return;
    }

    if (frameName == "") {
      const key = enqueueSnackbar("No Frame Name", {
        variant: "error",
      });
      return;
    }

    if (file.name != "frame_" + frameID + ".png") {
      const key = enqueueSnackbar("Upload a Valid File", {
        variant: "error",
      });
      return;
    }

    setLoading(true);
    const data = new FormData();

    data.append("file", file);
    let url = "/api/file/frameID/" + frameID;
    axios
      .post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
            variant: "error",
          });
        } else {
          const key = enqueueSnackbar("File - " + file.name + " uploaded", {
            variant: "success",
          });
          addFile(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
          variant: "error",
        });
      });
  };

  const addFile = (file) => {
    const currFiles = files;
    currFiles.push(file);
    setFiles([...currFiles]);
    var frameInfo = {
      FrameName: frameName.toUpperCase(),
      FrameID: frameID.toString(),
    };
    props.addFrameInfo(frameInfo);
    checkAndEnableAddFrame();
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadFile(file, type);
    }
  };

  const checkAndEnableAddFrame = () => {
    if (frameName != "" && frameID != "" && files.length > 0) {
      props.setEnableAddFrame(true);
    } else {
      props.setEnableAddFrame(false);
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.header}>
        <form
          className={classes.root}
          style={{ marginRight: 10 }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Frame ID"
            value={frameID}
            onChange={(event) => {
              setFrameID(event.target.value);
              checkAndEnableAddFrame();
            }}
            variant="outlined"
          />
        </form>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="outlined-basic"
            label="Frame Name"
            value={frameName}
            onChange={(event) => {
              setFrameName(event.target.value);
              checkAndEnableAddFrame();
            }}
            variant="outlined"
          />
        </form>
      </div>
      <DropBox
        type="main"
        uploadedFiles={files}
        handleDropFile={handleDropFile}
      />
    </Container>
  );
};
export default withStyles(FrameUploader, useStyles);
