import React, { useState, useEffect } from "react";
import Wrapper from "../components/Wrapper";
import { withStyles } from "tss-react/mui";
import {
  Container,
  FormControl,
  Select,
  MenuItem,
  Button,
  Grid,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { PropTypes } from "prop-types";
import DropBox from "../components/DropBox";
import { playEventCompletionSound } from "../utils/sound";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    margin: "5vh 10vw",
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const ClubEventsUploadWebLink = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [currentServer, setCurrentServer] = useState(0);
  const [responseEng, setResponseEng] = useState(null);
  const [responseArabic, setResponseArabic] = useState(null);
  const handleServerChange = (event) => {
    setCurrentServer(event.target.value);
  };
  const publishClubEventsUploadWebLink = (data) => {
    setLoading(true);
    let url = "/api/publishClubEventsWebLink/dev/" + currentServer;
    axios
      .post(url)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        setResponseEng(data.dataEng);
        setResponseArabic(data.dataArabic);
        const key = enqueueSnackbar("Publish Successful", {
          variant: "success",
        });
        playEventCompletionSound(true);
        setLoading(false);
      })
      .catch((e) => {
        if (e.response?.data) {
          enqueueSnackbar(e.response.data, {
            variant: "error",
            autoHideDuration: 3000,
            maxSnack: 4,
          });
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
            autoHideDuration: 3000,
            maxSnack: 4,
          });
        }
        playEventCompletionSound(false);
        setLoading(false);
      });
  };

  // Uploads bundle assets to the tool instance
  const uploadClubEvents = (file, type) => {
    setLoading(true);
    const data = new FormData();
    data.append("file", file);
    let url = `/api/file/uploadClubEventsWebLink/${type}`;
    axios
      .post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        const key = enqueueSnackbar("File - " + file.name + " uploaded", {
          variant: "success",
          autoHideDuration: 3000,
          maxSnack: 4,
        });
        addFile(data, type);
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
          variant: "error",
          autoHideDuration: 3000,
          maxSnack: 4,
        });
        setLoading(false);
      });
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadClubEvents(file, type);
    }
  };

  const addFile = (file, type) => {
    setFiles((current) => [...current, file]);
  };
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Wrapper
        heading="club events weblink Upload"
        setIsLoggedIn={props.setIsLoggedIn}
      >
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.header}>
            <div style={{ flexGrow: 1 }}></div>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="server-select"
                id="server-select"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={currentServer}
                onChange={handleServerChange}
              >
                <MenuItem value={0}>Dev</MenuItem>
                <MenuItem value={1}>Production</MenuItem>
              </Select>
            </FormControl>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={publishClubEventsUploadWebLink}
              disabled={loading}
            >
              Publish
              {loading && (
                <CircularProgress size={24} className={props.buttonProgress} />
              )}
            </Button>
          </div>
        </Container>
        <div style={{ margin: "5vh 10vw" }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <h2>Events Weblink upload </h2>
              <DropBox
                uploadedFiles={files}
                handleDropFile={handleDropFile}
                loading={loading}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>
        </div>
        <div>
          {(responseEng || responseArabic) && (
            <div>
              <h2>Response from API:</h2>
              <pre>{JSON.stringify(responseEng, null, 2)}</pre>
              <pre>{JSON.stringify(responseArabic, null, 2)}</pre>
            </div>
          )}
        </div>
      </Wrapper>
    </div>
  );
};

ClubEventsUploadWebLink.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(ClubEventsUploadWebLink, useStyles);
