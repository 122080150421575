import React, { useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
} from "@mui/material";
import { CountryList } from "../constants/countryList";

const CountrySelector = ({ countries, setCountries }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCountries(typeof value === "string" ? value.split(",") : value);
  };

  const getCountryName = (code) => {
    const countryItem = CountryList.find((country) => country.code === code);
    return countryItem ? countryItem.name : code;
  };

  return (
    <Grid item xs={12} sm={4}>
      <FormControl sx={{ m: 1, width: 250 }}>
        <InputLabel id="country-select-label">Countries</InputLabel>
        <Select
          labelId="country-select-label"
          id="country-select"
          multiple
          value={countries}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Countries" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={getCountryName(value)} />
              ))}
            </Box>
          )}
        >
          {CountryList.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default CountrySelector;
