import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { Grid, Divider } from "@mui/material";
import SingleInfoCard from "../components/SingleInfoCard";
import EmojiEvents from "@mui/icons-material/EmojiEvents";
import Event from "@mui/icons-material/Event";
import Description from "@mui/icons-material/Description";
import {
  ServerEventTypes,
  ServerSubEventTypes,
} from "../constants/constants.ts";

const useStyles = makeStyles()((theme) => ({
  eventData: {
    marginBottom: 30,
  },
}));

const PAYER_TYPE = ["No Payer", "Payer", "Both"];

export default function LiveEvent(props) {
  const { classes } = useStyles();

  var eventList = props.liveInfo["ED"];
  if (!eventList) {
    return <div></div>;
  }
  var eventViews = [];
  for (let event of eventList) {
    let startDate = new Date(event["start_date"]);
    let endDate = new Date(event["end_date"]);

    eventViews.push(
      <div className={classes.eventData}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <SingleInfoCard
              heading="Event Number"
              content={event["event_number"]}
              icon={<EmojiEvents />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SingleInfoCard
              heading="Event Type"
              content={ServerEventTypes.get(event["event_type"])}
              icon={<EmojiEvents />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SingleInfoCard
              heading="Event Sub Type"
              content={ServerSubEventTypes.get(event["sub_event_type"])}
              icon={<EmojiEvents />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SingleInfoCard
              heading="Event Start Time (IST)"
              content={startDate.toLocaleString()}
              icon={<Event />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SingleInfoCard
              heading="Event End Time (IST)"
              content={endDate.toLocaleString()}
              icon={<Event />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SingleInfoCard
              heading="User Ids"
              content={event["user_ids"].join(" - ")}
              icon={<Event />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SingleInfoCard
              heading="Country Cohort"
              content={event["country_cohort"]}
              icon={<Event />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SingleInfoCard
              heading="Payer Cohort"
              content={PAYER_TYPE[event["payer_cohort"]]}
              icon={<Event />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SingleInfoCard
              heading="Event Config Version"
              content={event["evc"]}
              icon={<Description />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SingleInfoCard
              heading="Locale Config Version"
              content={event["loc"]}
              icon={<Description />}
            />
          </Grid>
        </Grid>
        <br></br>
        <br></br>
        <Divider></Divider>
      </div>,
    );
  }

  return <div>{eventViews}</div>;
}

LiveEvent.propTypes = {
  children: PropTypes.node,
};
