import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { Grid, Divider } from "@mui/material";
import SingleInfoCard from "../components/SingleInfoCard";
import Description from "@mui/icons-material/Description";

const useStyles = makeStyles()((theme) => ({
  eventData: {
    marginBottom: 30,
  },
}));

export default function LiveConfigVersion(props) {
  const { classes } = useStyles();

  return (
    <div className={classes.eventData}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <SingleInfoCard
            heading="Dice Config Version"
            content={props.liveInfo["DCE"]}
            icon={<Description />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SingleInfoCard
            heading="Token Config Version"
            content={props.liveInfo["TKC"]}
            icon={<Description />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SingleInfoCard
            heading="Frame Config Version"
            content={props.liveInfo["FAC"]}
            icon={<Description />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SingleInfoCard
            heading="Board Config Version"
            content={props.liveInfo["BDC"]}
            icon={<Description />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SingleInfoCard
            heading="Avatar Config Version"
            content={props.liveInfo["AVC"]}
            icon={<Description />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SingleInfoCard
            heading="Locale Config Version"
            content={props.liveInfo["NV"] - 1}
            icon={<Description />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SingleInfoCard
            heading="New Dice Config Version"
            content={props.liveInfo["NDC"]}
            icon={<Description />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SingleInfoCard
            heading="New Token Config Version"
            content={props.liveInfo["NTC"]}
            icon={<Description />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SingleInfoCard
            heading="New Frame Config Version"
            content={props.liveInfo["NFC"]}
            icon={<Description />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SingleInfoCard
            heading="App Config Version"
            content={props.liveInfo["APP"]}
            icon={<Description />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SingleInfoCard
            heading="Base Config Version"
            content={props.liveInfo["CON"]}
            icon={<Description />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SingleInfoCard
            heading="Clubs DP Config Version"
            content={props.liveInfo["CBC"]}
            icon={<Description />}
          />
        </Grid>
        {props.liveInfo["club"] && (
          <>
            <Grid item xs={12} sm={4}>
              <SingleInfoCard
                heading="Clubs Gift Config Version"
                content={props.liveInfo["club"]["CGC"]}
                icon={<Description />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SingleInfoCard
                heading="Clubs Entry Effect Config Version"
                content={props.liveInfo["club"]["CCEEC"]}
                icon={<Description />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SingleInfoCard
                heading="Clubs Background Config Version"
                content={props.liveInfo["club"]["CBGC"]}
                icon={<Description />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SingleInfoCard
                heading="Clubs Chat Bubble Config Version"
                content={props.liveInfo["club"]["CCCBC"]}
                icon={<Description />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SingleInfoCard
                heading="Clubs Profile Slider Config Version"
                content={props.liveInfo["club"]["CCPSC"]}
                icon={<Description />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SingleInfoCard
                heading="Clubs Profile Frames Config Version"
                content={props.liveInfo["club"]["CCPFC"]}
                icon={<Description />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SingleInfoCard
                heading="Clubs Frames Config Version"
                content={props.liveInfo["club"]["CCFC"]}
                icon={<Description />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SingleInfoCard
                heading="Clubs User Profile Config Version"
                content={props.liveInfo["club"]["CRC"]}
                icon={<Description />}
              />
            </Grid>
          </>
        )}
      </Grid>
      <br></br>
      <br></br>
      <Divider></Divider>
    </div>
  );
}

LiveConfigVersion.propTypes = {
  children: PropTypes.node,
};
