import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Avatar, Menu, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getUserImage } from "../utils/tokenUtils";
import axios from "axios";

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    padding: theme.spacing(4),
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
}));

const options = ["SignOut"];

export default function Wrapper(props) {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={classes.wrapper}>
      <div style={{}} className={classes.header}>
        <Typography variant="h4">{props.heading}</Typography>
        <div style={{ flexGrow: 1 }}></div>
        <Avatar src={getUserImage()}></Avatar>
        <IconButton aria-haspopup="true" onClick={handleClick}>
          <MoreVertIcon style={{ color: "#565656" }} />
        </IconButton>
      </div>
      <br />
      <Divider />
      {props.children}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              axios
                .post("/signout")
                .then((email) => {
                  window.google.accounts.id.revoke(email, (done) => {
                    if (done.error !== "") {
                      console.error(`error in revoking token: ${done.error}`);
                    } else {
                      console.log(`token revoked successfully`);
                    }
                  });
                  props.setIsLoggedIn(false);
                  handleClose();
                  window.location.reload();
                })
                .catch((err) => {
                  console.log(`error in signing out, error: ${err}`);
                  alert("error in logging out user");
                });
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

Wrapper.propTypes = {
  children: PropTypes.node,
};
