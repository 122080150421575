import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  heading: {
    display: "flex",
    spacing: 10,
  },
}));

export default function SingleInfoCard(props) {
  const { classes } = useStyles();

  return (
    <Paper className={classes.paper}>
      <div className={classes.heading}>
        {props.icon}
        <Typography style={{ marginLeft: 6 }} color="textSecondary">
          {props.heading}
        </Typography>
      </div>
      <br></br>
      <Typography style={{ color: "#1881ce" }} variant="h5">
        {props.content}
      </Typography>
    </Paper>
  );
}

SingleInfoCard.propTypes = {
  children: PropTypes.node,
};
