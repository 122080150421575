import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Container, TextField } from "@mui/material";
import DropBox from "./DropBox";
import axios from "axios";
import { useSnackbar } from "notistack";
import { withStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const AvatarUploader = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();

  const [files, setFiles] = useState([]);
  const [avatarID, setAvatarID] = useState(props.avatarID);
  const [avatarPrice, setAvatarPrice] = useState("800");
  const [loading, setLoading] = useState(false);

  const uploadFile = (file, type) => {
    if (avatarID == "") {
      const key = enqueueSnackbar("No Avatar ID", {
        variant: "error",
      });
      return;
    }
    if (avatarPrice == "") {
      const key = enqueueSnackbar("No Avatar Price", {
        variant: "error",
      });
      return;
    }
    if (isNaN(avatarPrice) || isNaN(parseFloat(avatarPrice))) {
      const key = enqueueSnackbar("Invalid Avatar Price", {
        variant: "error",
      });
      return;
    }
    if (file.name != "sa_" + avatarID + ".png") {
      const key = enqueueSnackbar("Upload a Valid File", {
        variant: "error",
      });
      return;
    }

    setLoading(true);
    const data = new FormData();
    data.append("file", file);
    let url = "/api/file/avatarID/" + avatarID;
    axios
      .post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
            variant: "error",
          });
        } else {
          const key = enqueueSnackbar("File - " + file.name + " uploaded", {
            variant: "success",
          });
          addFile(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
          variant: "error",
        });
      });
  };

  const addFile = (file) => {
    const currFiles = files;
    currFiles.push(file);
    setFiles([...currFiles]);
    var avatarInfo = {
      AvatarPrice: avatarPrice,
      AvatarID: avatarID.toString(),
      AvatarName: "Special avatar " + avatarID,
    };
    props.addAvatarInfo(avatarInfo);
    checkAndEnableAddAvatar();
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadFile(file, type);
    }
  };

  const checkAndEnableAddAvatar = () => {
    if (avatarPrice != "" && avatarID != "" && files.length > 0) {
      props.setEnableAddAvatar(true);
    } else {
      props.setEnableAddAvatar(false);
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.header}>
        <form
          className={classes.root}
          style={{ marginRight: 10 }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Avatar ID"
            value={avatarID}
            onChange={(event) => {
              setAvatarID(event.target.value);
              checkAndEnableAddAvatar();
            }}
            variant="outlined"
          />
        </form>
        <form
          className={classes.root}
          style={{ marginRight: 10 }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Avatar Name"
            disabled
            value={"Special avatar " + avatarID}
            variant="outlined"
          />
        </form>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="outlined-basic"
            label="Avatar Price"
            value={avatarPrice}
            onChange={(event) => {
              setAvatarPrice(event.target.value);
              checkAndEnableAddAvatar();
            }}
            variant="outlined"
          />
        </form>
      </div>
      <DropBox
        type="main"
        uploadedFiles={files}
        handleDropFile={handleDropFile}
      />
    </Container>
  );
};
export default withStyles(AvatarUploader, useStyles);
