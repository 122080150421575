import React, { useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
} from "@mui/material";
import { LanguageList } from "../constants/languageList";

const LanguageSelector = ({ languages, setLanguages }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLanguages(typeof value === "string" ? value.split(",") : value);
  };

  const getFullForm = (shortForm) => {
    const language = LanguageList.find((lang) => lang.shortForm === shortForm);
    return language ? language.fullForm : shortForm;
  };

  return (
    <Grid item xs={12} sm={4}>
      <FormControl sx={{ m: 1, width: 250 }}>
        <InputLabel id="language-select-label">Languages</InputLabel>
        <Select
          labelId="language-select-label"
          id="language-select"
          multiple
          value={languages}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Languages" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={getFullForm(value)} />
              ))}
            </Box>
          )}
        >
          {LanguageList.map((lang) => (
            <MenuItem key={lang.shortForm} value={lang.shortForm}>
              {lang.fullForm}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default LanguageSelector;
