import React from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Button } from "@mui/material";
import { Component } from "react";
import { PropTypes } from "prop-types";
import Wrapper from "../components/Wrapper";
import DiceValidateUploader from "../components/DiceValidateUploader";
import diceFiles from "../components/DiceValidateUploader";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonNumber: {
    color: "red",
    marginTop: 20,
    padding: 20,
    height: 40,
    width: 40,
    backgroundColor: "green",
  },
});

class DiceValidate extends Component {
  state = {
    loading: false,
    currentServer: 0,
    DiceInfos: [],
    DiceValidators: [],
    addDiceEnabled: false,
  };

  handleServerChange = (event) => {
    this.setState({
      currentServer: event.target.value,
    });
  };

  setEnableAddDice = (isEnabled) => {
    this.setState({
      addDiceEnabled: isEnabled,
    });
  };

  addDiceInfos = (diceInfo) => {
    var newDiceInfo = true;
    if (this.state.DiceInfos.length > 0) {
      this.state.DiceInfos.map((v, i) => {
        if (diceInfo.DiceID == v.DiceID) {
          newDiceInfo = false;
        }
      });
    }

    if (newDiceInfo) {
      var diceInfos = this.state.DiceInfos;
      diceInfos.push(diceInfo);
      this.setState({
        DiceInfos: diceInfos,
      });
    }
  };

  getDiceValidators = () => {
    var DiceValidator = [];

    if (this.state.DiceValidators.length > 0) {
      DiceValidator = this.state.DiceValidators;
    } else {
      DiceValidator.push(
        <Grid alignItems="flex-start" container spacing={1}>
          <Grid container direction="column" item xs={6} spacing={1}>
            <DiceValidateUploader
              props={this.props}
              addDiceInfo={this.addDiceInfos}
              setEnableAddDice={this.setEnableAddDice}
            />
          </Grid>
          <Grid
            container
            direction="column"
            item
            xs={6}
            spacing={1}
            align="center"
            justify="center"
          >
            <iframe
              src="https://psbusiness.superkinglabs.com/Dice/dice.html"
              style={{
                width: "100%",
                height: "640px",
                overflow: "visible",
                marginTop: "20px",
                border: "none",
              }}
            />
          </Grid>
        </Grid>,
      );
      this.setState({ DiceValidators: DiceValidator });
    }

    return DiceValidator;
  };

  render() {
    for (var i = 0; i < diceFiles; i++) {
      // note: we are adding a key prop here to allow react to uniquely identify each
      // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    }
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Wrapper
          heading="Dice Validate"
          setIsLoggedIn={this.props.setIsLoggedIn}
        >
          {this.getDiceValidators()}
        </Wrapper>
      </div>
    );
  }
}

DiceValidate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(DiceValidate, useStyles);
