import React, { useEffect, useState } from "react";
import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import ValidatorDashboard from "./views/ValidatorDashboard";
import axios from "axios";

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    axios
      .get("/oauth")
      .then(() => {
        console.log(`user is authed`);
        setIsLoggedIn(true);
      })
      .catch((err) => {
        console.error(`error in getting /oauth, error: ${err}`);
      });
  }, []);

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      maxSnack={6}
    >
      <HashRouter>
        <div>
          <Switch>
            <PrivateRoute
              path="/dashboard"
              component={Dashboard}
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
            />
            <PrivateRoute
              path="/validator"
              component={ValidatorDashboard}
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
            />
            <Route
              path="/"
              render={(props) => (
                <Login
                  {...props}
                  isLoggedIn={isLoggedIn}
                  setIsLoggedIn={setIsLoggedIn}
                />
              )}
            />
          </Switch>
        </div>
      </HashRouter>
    </SnackbarProvider>
  );
}

// protected route, only logged in users can visit this page
function PrivateRoute({
  component: Component,
  isLoggedIn,
  setIsLoggedIn,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} setIsLoggedIn={setIsLoggedIn} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
