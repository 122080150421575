import React from "react";
import PropTypes from "prop-types";
import DropZone from "react-drop-zone";
import "react-drop-zone/dist/styles.css";
import { makeStyles } from "tss-react/mui";
import { CircularProgress } from "@mui/material";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  container: {
    maxHeight: 440,
  },
}));

export default function DropBox(props) {
  const { classes } = useStyles();

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table stickyHeader className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>FileName</TableCell>
            <TableCell align="right">
              <DropZone
                multiple
                onDrop={(fileList, text) => {
                  props.handleDropFile(fileList, props.type);
                }}
              >
                {({ over, overDocument }) => (
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={props.loading}
                  >
                    {!props.loading && "Upload"}
                    {props.loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                )}
              </DropZone>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.uploadedFiles.map((file, index) => {
            return <TableRow key={index}>
              <TableCell component="th" scope="row">
                {file}
              </TableCell>
              <TableCell align="right">
                {"Uploaded "}
                {props.handleDeleteFile && ( // Check if handleDeleteFile is set
                  <span className="delete-button-container">
                    <IconButton
                      aria-label="delete"
                      color="error"
                      onClick={() => props.handleDeleteFile(file, props.type)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                )}
              </TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

DropBox.propTypes = {
  children: PropTypes.node,
};
