import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Container, TextField } from "@mui/material";
import DropBox from "./DropBox";
import axios from "axios";
import { useSnackbar } from "notistack";
import { withStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DiceValidateUploader = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { classes } = useStyles();

  const [files, setFiles] = useState([]);
  const [diceID, setDiceID] = useState("");
  const [loading, setLoading] = useState(false);

  const diceFiles = [
    "dice0",
    "dice1",
    "dice2",
    "dice3",
    "dice4",
    "dice5",
    "dice6",
    "diceface",
    "diceroll1",
    "diceroll2",
    "diceroll3",
    "diceroll4",
    "diceroll5",
    "diceroll6",
  ];

  const uploadFile = (file, type) => {
    if (diceID == "") {
      const key = enqueueSnackbar("No Dice ID", {
        variant: "error",
        persist: true,
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return;
    }

    let found = false;
    for (var fileName of diceFiles) {
      if (file.name == fileName + "_" + diceID + ".png") {
        found = true;
      }
    }

    if (!found) {
      const key = enqueueSnackbar("Upload a Valid File", {
        variant: "error",
        persist: true,
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return;
    }

    setLoading(true);
    const data = new FormData();
    data.append("file", file);
    let url = "/api/validation/dice/upload/";
    axios
      .post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("File - " + file.name + " not uploaded. " + data.err, {
            variant: "error",
            persist: true,
            onClick: () => {
              closeSnackbar(key);
            },
          });
        } else {
          const key = enqueueSnackbar("File - " + file.name + " uploaded", {
            variant: "success",
            persist: true,
            onClick: () => {
              closeSnackbar(key);
            },
          });
          addFile(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const key = enqueueSnackbar("File - " + file.name + " not uploaded. " + err, {
          variant: "error",
          persist: true,
          onClick: () => {
            closeSnackbar(key);
          },
        });
      });
  };

  const addFile = (file) => {
    const currFiles = files;
    currFiles.push(file);
    setFiles([...currFiles]);
    var diceInfo = {
      DiceID: diceID.toString(),
    };
    props.addDiceInfo(diceInfo);
    checkAndEnableAddDice();
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadFile(file, type);
    }
  };

  const checkAndEnableAddDice = () => {
    if (diceID != "" && files.length > 0) {
      props.setEnableAddDice(true);
    } else {
      props.setEnableAddDice(false);
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.header}>
        <form
          className={classes.root}
          style={{ marginRight: 10 }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Dice ID"
            value={diceID}
            onChange={(event) => {
              setDiceID(event.target.value);
              checkAndEnableAddDice();
            }}
            variant="outlined"
          />
        </form>
      </div>
      <DropBox
        type="main"
        uploadedFiles={files}
        handleDropFile={handleDropFile}
      />
    </Container>
  );
};
export default withStyles(DiceValidateUploader, useStyles);
