export const REQUIRED_MAIN_FILES_KTM3 = `REQUIRED_MAIN_FILES = [
    "reward_container.png",
    "event_progress_bar_base.png",
    "monster_health.png",
    "doll_tick.png",
    "cannon_dice_card.png",
    "meter.png",
    "event_sale_text.png",
    "needle.png",
    "collectible_1.png",
    "multi_collectible_2.png",
    "reward_arrow.png",
    "hit_shape.png",
    "event_progress_bar_completed.png",
    "cannon_reward_logo.png",
    "event_index_circle.png",
    "collectible_2.png",
    "cannon_event_banner.png",
    "cannon_event_bg.png",
    "cannon.png",
    "cannon_multi_reward_node.csb",
    "progress_bar_node.csb",
    "multi_cannon_reward_view.csb",
    "doll_reward_node.csb",
]`

export const REQUIRED_BG_FILES_KTM3 = `REQUIRED_BG_FILES = ["happy.png", "unhappy.png"]`

export const REQUIRED_MAIN_FILES_KTM4 = `REQUIRED_MAIN_FILES = [
    "normal_card_base.png",
    "royal_card_base.png",
    "target_icon_teaser.png",
    "event_sale_text.png",
    "free_pass_base.png",
    "ribbon_sides.png",
    "icon_lock_new.png",
    "collectible_2.png",
    "header_art.png",
    "ray_purchase_popup.png",
    "normal_base.png",
    "progressbar_pointer.png",
    "active_tab_shine.png",
    "icon_completed.png",
    "ktm_progressbar_base.png",
    "spark.png",
    "meter_highlight_glow.png",
    "header_art_gameplay.png",
    "progressbar_filler_base.png",
    "target_hit.png",
    "royal_pass_icon.png",
    "royal_base.png",
    "cannon.png",
    "royal_pass_rewardmap.png",
    "spark_particle_white.png",
    "collectible_1.png",
    "purchase_popup_art.png",
    "multi_collectible_2.png",
    "target_lost.png",
    "sparkle_glow.png",
    "meter.png",
    "diamond_icon_yellow_large.png",
    "reward_level_sides.png",
    "arrow_rules.png",
    "arrow_pointer.png",
    "ktm_tooltip.png",
    "levelup_glow_quarter.png",
    "ribbon_center.png",
    "target_idle.png",
    "target_hit.mp3",
    "cannon_ready.mp3",
    "cannon_release.mp3",
    "event_level_upgrade.mp3",
    "defaultParticle.plist",
    "ktm_sparkle_medium.plist",
    "particle_primary_ammo.plist",
    "particle_spark.plist",
    "glowburst_circularV2.plist",
    "ktm_sparkle_small.plist",
    "particle_primary_ammo_main.plist",
    "sparkle_burst.plist",
    "ktm_particle_sparkburst.plist",
    "particle_debris.plist",
    "particle_secondary_ammo.plist",
    "ktm_royal_card_particle.plist",
    "particle_debris_rt.plist",
    "particle_smoke.plist",
    "ktm_sparkle_large.plist",
    "particle_fireflies.plist",
    "particle_smoke_rt.plist",
    "ktm_game.csb",
    "ktm_game_ammo.csb",
    "ktm_game_monster.csb",
    "ktm_gameplay_popup.csb",
    "ktm_rewardmap_scroll_card.csb",
    "progress_bar_icon.csb",
]`

export const REQUIRED_BG_FILES_KTM4 = `REQUIRED_BG_FILES = ["teaser_bg.png", "gameplay_bg.png"]`

export const REQUIRED_MAIN_FILES_TREvent = `REQUIRED_MAIN_FILES = [
    "dice_impact.plist",
    "dice_impact.png",
    "event{}_treasure_road_board.csb",
    "tile_node.csb",
    "treasure_road_info_view.csb",
    "treasure_road_main_view.csb",
    "treasure_road_reward_view.csb",
    "treasure_road_event_banner.png",
    "treasure_road_dice_button.png",
    "treasure_road_event_btn_image.png",
    "treasure_road_dice_4.png",
    "treasure_road_dice_frame_2.png",
    "treasure_road_dice_frame_3.png",
    "treasure_road_dice_6.png",
    "treasure_road_dice_1.png",
    "treasure_road_dice_5.png",
    "treasure_road_dice_frame_1.png",
    "treasure_road_dice_2.png",
    "treasure_road_dice_default.png",
    "treasure_road_dice_3.png",
    "treasure_road_dice_frame_0.png",
    "treasure_road_token.png",
    "event_sale_text.png",
    "treasure_road_track.png",
    "treasure_road_token_shadow.png",
    "collectible_1.png",
    "treasure_road_collectibles.png",
    "treasure_road_booster.png",
    "collectible_2.png",
    "treasure_road_cross.png",
]`

export const REQUIRED_BG_FILES_TREvent = `REQUIRED_BG_FILES = ["treasure_road_path.png"]`

export const REQUIRED_MAIN_FILES_RBox = `REQUIRED_MAIN_FILES = [
    "rewardbox_theme_{}.skel",
    "reward_season_intro_main.png",
    "skip_content_bg.png",
]`

export const REQUIRED_RESOLUTION_WISE_FILES_RBox = `RESOLUTION_WISE_REQUIRED_FILES = ["rewardbox_theme_{}.atlas", "rewardbox_theme_{}.png"]`


export const REQUIRED_MAIN_FILES_SeasonUploads = `REQUIRED_MAIN_FILES = [
    "season_banner.png",
    "banner_season.png",
    "golden_pass.png",
    "sp_shop.png",
    "season_board.png",
    "season_end_icon.png",
    "goal.png",
    "lobby_season_banner.png",
    "season_icon.png",
    "glowburst_conicalglowless.plist",
    "market_shop_texture.plist",
    "season_star_particle.plist",
    "season_texture_circle.plist",
    "season_pass_popup.csb",
    "season_pass_popup_exclusive.csb",
]`

export const REQUIRED_BG_FILES_SeasonUploads = `REQUIRED_BG_FILES = ["season_purchase_main_icon.png"]`