import React from "react";
import { withStyles } from "tss-react/mui";
import {
  IconButton,
  Container,
  CircularProgress,
  Menu,
  MenuItem,
  Backdrop,
  LinearProgress,
} from "@mui/material";
import { useState } from "react";
import { PropTypes } from "prop-types";
import { useSnackbar } from "notistack";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircle from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import SingleFormDialog from "../components/SingleFormDialog";
import Wrapper from "../components/Wrapper";
import EventEditor from "../components/EventEditor";
import axios from "axios";
import PublishDialog from "../components/PublishDialog";
import { EventNames, EventWarnings } from "../constants/constants.ts";
import { playEventCompletionSound } from "../utils/sound";

const useStyles = (theme) => ({
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  root: {
    width: "80%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexGrow: 1,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  headingBar: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  backdrop: {
    flexDirection: "column",
    display: "flex",
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loaderWrapper: {
    display: "flex",
    marginTop: theme.spacing(10),
    justifyContent: "center",
  },
});

const Events = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState("");
  const [panelIndex, setpanelIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [openFull, setOpenFull] = useState(false);
  const [publishOpen, setPublishOpen] = useState(false);
  const [activeReleaseIndex, setActiveReleaseIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState("");
  const [percent, setPercent] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [warningString, setWarningString] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (panel, panelIndex) => (event, isExpanded) => {
    if (expanded === panel) {
      setExpanded("");
      setpanelIndex(panelIndex);
    } else {
      setExpanded(panel);
      setpanelIndex(panelIndex);
    }
  };

  const publishEventToServer = (index) => {
    setLoading(true);
    setStage("Uploading Event Images");
    setPercent(0);

    if (activeReleaseIndex == 2) {
      publishServerConfigs(index);
      return;
    }
    var eventID = props.eventDrafts[panelIndex].ID;
    let url =
      "/api/uploadEventFiles/eventID/" + eventID + "/dev/" + activeReleaseIndex;
    axios
      .post(url, {})
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Publish Failed", {
            variant: "error",
          });
          setLoading(false);
        } else {
          publishEventConfigsToServer(eventID);
        }
      })
      .catch((err) => {
        const key = enqueueSnackbar("Publish Failed", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const publishEventConfigsToServer = (eventID) => {
    setLoading(true);
    setStage("Setting up Client Configs");
    setPercent(50);

    let url =
      "/api/uploadEventConfigFiles/eventID/" +
      eventID +
      "/dev/" +
      activeReleaseIndex;
    axios
      .post(url, {})
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        console.log("data: ", data);
        if (data.err) {
          const key = enqueueSnackbar("Publish Failed", {
            variant: "error",
          });
          setLoading(false);
        } else {
          publishServerConfigs(eventID);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        const key = enqueueSnackbar("Publish Failed", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const publishServerConfigs = (eventID) => {
    setLoading(true);
    setStage("Setting up Server Configs");
    setPercent(80);

    let url =
      "/api/publishEvent/eventID/" + eventID + "/dev/" + activeReleaseIndex;
    axios
      .post(url, props.eventDrafts[panelIndex].EventData)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Publish Failed", {
            variant: "error",
          });
          playEventCompletionSound(false);
          setLoading(false);
        } else {
          const key = enqueueSnackbar("Publish Successful", {
            variant: "success",
          });
          playEventCompletionSound(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        const key = enqueueSnackbar("Publish Failed", {
          variant: "error",
        });
        playEventCompletionSound(false);
        setLoading(false);
      });
  };

  const publishEvent = (index) => {
    var eventID = props.eventDrafts[panelIndex].ID;
    let url = "/api/validateEvent/eventID/" + eventID;
    axios
      .post(url, props.eventDrafts[panelIndex].EventData)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Publish Failed", {
            variant: "error",
          });
          setLoading(false);
          return;
        }
        if (data.warning) {
          if (EventWarnings.has(data.warning)) {
            setWarningString(EventWarnings.get(data.warning));
          }
        }
        setPublishOpen(true);
        setActiveReleaseIndex(index);
        setAnchorEl(null);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Publish Failed", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const checkEventData = (eventData, prod) => {
    if (eventData == null) {
      return "Event data is null";
    }
    if (eventData.EventData.EventThemeInfo.DefaultAsset === false) {
      if (eventData.BackgroundFiles.length == 0)
        return "Event Background files missing";
      if (eventData.MainFiles.length == 0) return "Event Main files missing";
    }

    if (eventData.EventData.EventThemeInfo.Name === "")
      return "Event Theme type missing";
    if (eventData.EventData.EventID == 0) return "Event ID missing";
    if (eventData.EventData.EVC == 0) return "EVC missing";
    if (eventData.EventData.LOC == 0) return "LOC missing";

    if (eventData.EventData.StartDate == "0001-01-01T00:00:00Z")
      return "Null start date";
    if (eventData.EventData.EndDate == "0001-01-01T00:00:00Z")
      return "Null end date";

    let startDate = new Date(eventData.EventData.StartDate);
    let endDate = new Date(eventData.EventData.EndDate);
    if (endDate <= startDate) return true;
    var eventList = props.liveInfo["ED"];
    if (eventList) {
      for (let event of eventList) {
        let liveStartDate = new Date(event["start_date"]);
        let liveEndDate = new Date(event["end_date"]);

        if (eventData.EventData.EventID <= event["event_number"]) {
          return true;
        }

        if (eventData.EventData.EVC < event["evc"])
          return "EVC is less than live version";
        if (eventData.EventData.LOC < event["loc"])
          return "LOC is less than live version";

        if (prod) {
          if (startDate < liveStartDate) return "Invalid start date";
          if (startDate < liveEndDate) return "Invalid start date";

          if (endDate < liveStartDate) return "Invalid end date";
          if (endDate < liveEndDate) return "Invalid end date";
        }
      }
    }

    return false;
  };

  const getEventDrafts = () => {
    const { classes } = props;
    if (props.userLoading) {
      return (
        <div className={classes.loaderWrapper}>
          <CircularProgress className={classes.loader} color="secondary" />
        </div>
      );
    }

    var errorMessage = checkEventData(props.eventDrafts[panelIndex], false);
    var disablePublish = errorMessage ? true : false;
    var disablePublishProd = true;
    var messageDiv;
    if (errorMessage) {
      messageDiv = (
        <div
          style={{ paddingLeft: "20px", paddingBottom: "10px", color: "red" }}
        >
          {errorMessage}
        </div>
      );
    } else {
      messageDiv = (
        <div
          style={{ paddingLeft: "20px", paddingBottom: "10px", color: "green" }}
        >
          Ready for publish
        </div>
      );
    }
    var eventDrafts = [];
    if (props.eventDrafts) {
      props.eventDrafts.map((option, index) => {
        eventDrafts.push(
          <Accordion
            key={option.Name}
            expanded={expanded === option.Name}
            onChange={handleChange(option.Name, index)}
          >
            <AccordionSummary
              className={classes.headingBar}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>{option.Name}</Typography>
              <Typography className={classes.secondaryHeading}>
                {EventNames.get(option.EventType)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Button
                variant="outlined"
                style={{ marginRight: "10px" }}
                onClick={() => setOpenFull(true)}
                color="primary"
              >
                Edit
              </Button>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="outlined"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                disabled={disablePublish}
                color="primary"
              >
                Publish
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                  setAnchorEl(null);
                }}
              >
                <MenuItem onClick={() => publishEvent(0)}>Dev</MenuItem>
                <MenuItem onClick={() => publishEvent(1)}>Staging</MenuItem>
                <MenuItem
                  disabled={disablePublishProd}
                  onClick={() => publishEvent(2)}
                >
                  Production
                </MenuItem>
              </Menu>
              <div style={{ flexGrow: 1 }} />
              <Button
                variant="outlined"
                onClick={() => props.removeEventDraft(option.ID)}
                color="secondary"
              >
                Delete
              </Button>
            </AccordionDetails>
            {messageDiv}
          </Accordion>
        );

        return eventDrafts;
      });
    }

    return eventDrafts;
  };

  const handleDialogClose = () => {
    setWarningString("");
    setPublishOpen(false);
  };

  const { classes } = props;
  return (
    <div>
      <Wrapper heading="Event Drafts" setIsLoggedIn={props.setIsLoggedIn}>
        <Container maxWidth="sm" className={classes.container}>
          <div className={classes.header}>
            <Typography color="textSecondary" variant="h5">
              Drafts
            </Typography>
            <div style={{ flexGrow: 1 }}></div>
            <IconButton
              onClick={() => {
                handleClickOpen();
              }}
            >
              <AddCircle color="primary" fontSize="large" />
            </IconButton>
          </div>
          {getEventDrafts()}
        </Container>
      </Wrapper>
      <SingleFormDialog
        open={open}
        heading="New Event Draft"
        label="Name"
        handleAccept={props.addEventDraft}
        handleClose={() => setOpen(false)}
      />
      <PublishDialog
        publishEventToServer={publishEventToServer}
        open={publishOpen}
        eventData={props.eventDrafts ? props.eventDrafts[panelIndex] : null}
        handleChange={props.updateEvent}
        activeReleaseIndex={activeReleaseIndex}
        warningString={warningString}
        handleClose={handleDialogClose}
      />
      <EventEditor
        eventID={props.eventDrafts.UID}
        open={openFull}
        updateEvent={props.updateEvent}
        setUserData={props.setUserData}
        addFile={props.addFile}
        removeFile={props.removeFile}
        event={props.eventDrafts ? props.eventDrafts[panelIndex] : null}
        handleClose={() => setOpenFull(false)}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <Typography variant="h5">{stage}</Typography>
        <br></br>
        <br></br>
        <div className={classes.root}>
          <LinearProgress variant="determinate" value={percent} />
        </div>
      </Backdrop>
    </div>
  );
};

Events.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Events, useStyles);
