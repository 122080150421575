import { Environment, EnvironmentType } from "../globals/conf.ts";

const testUserName = "Testy User";
const testEmail = "test@mail.com";

export const saveUserDetails = (name, image, email) => {
  localStorage.setItem("name", name);
  localStorage.setItem("image", image);
  localStorage.setItem("email", email);
};

export const getUserName = () => {
  if (Environment.env == EnvironmentType.Local) {
    return testUserName;
  }
  return localStorage.getItem("name");
};

export const getUserImage = () => {
  return localStorage.getItem("image");
};

export const getUserEmail = () => {
  if (Environment.env == EnvironmentType.Local) {
    return testEmail;
  }
  return localStorage.getItem("email");
};
