import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Container,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ColorInput = (props) => {
  const  classes  = useStyles();

  const [isRedColorValid, setIsRedColorValid] = useState(true);
  const [isGreenColorValid, setIsGreenColorValid] = useState(true);
  const [isBlueColorValid, setIsBlueColorValid] = useState(true);

  const ColorName = {
    RED: 0,
    GREEN: 1,
    BLUE: 2,
  };

  const validateColor = (color, colorName) => {
    let valid = color != NaN && color >= 0 && color <= 255;
    if (colorName == ColorName.RED) setIsRedColorValid(valid);
    if (colorName == ColorName.GREEN) setIsGreenColorValid(valid);
    if (colorName == ColorName.BLUE) setIsBlueColorValid(valid);
    return valid;
  };
  return (
    <Container maxWidth="lg" className={classes.container}>
      <div
        className={classes.header}
        style={{ justifyContent: "space-between" }}
      >
        <div className={classes.header}>
          <Typography color="textSecondary" variant="h10">
            {props.label}
          </Typography>
        </div>
        <form className={classes.root} autoComplete="off">
          <TextField
            id="outlined-basic"
            label="Red"
            value={props.color[0]}
            onChange={(event) => {
              if (event.target.value.trim() == "") event.target.value = "0";
              let newColor = parseInt(event.target.value, 10);
              validateColor(newColor, ColorName.RED);
              props.setColor([newColor, props.color[1], props.color[2]]);
            }}
            error={!isRedColorValid}
            variant="outlined"
          />
        </form>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="outlined-basic"
            label="Green"
            value={props.color[1]}
            onChange={(event) => {
              if (event.target.value.trim() == "") event.target.value = "0";
              let newColor = parseInt(event.target.value, 10);
              validateColor(newColor, ColorName.GREEN);
              props.setColor([props.color[0], newColor, props.color[2]]);
            }}
            error={!isGreenColorValid}
            variant="outlined"
          />
        </form>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="outlined-basic"
            label="Blue"
            value={props.color[2]}
            onChange={(event) => {
              if (event.target.value.trim() == "") event.target.value = "0";
              let newColor = parseInt(event.target.value, 10);
              validateColor(newColor, ColorName.BLUE);
              props.setColor([props.color[0], props.color[1], newColor]);
            }}
            error={!isBlueColorValid}
            variant="outlined"
          />
        </form>
      </div>
    </Container>
  );
};

export default ColorInput;
