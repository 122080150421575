import React from "react";
import { Typography, createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  typography: {
    toolTipHeader: {
      color: "orange",
    },
    toolTipMessage: {
      color: "white",
    },
  },
});

export default function GetToolTipLine({ left, right }) {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography variant="toolTipHeader">{left}</Typography>
        <Typography variant="toolTipMessage">{right}</Typography>
        <br />
      </ThemeProvider>
    </div>
  );
}
