import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import {
  Container,
  FormControl,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from "@mui/material";
import { PropTypes } from "prop-types";
import { useSnackbar } from "notistack";
import Wrapper from "../components/Wrapper";
import axios from "axios";
import ClubsEventsUploader from "../components/ClubsEventsUploader";
import { playEventCompletionSound } from "../utils/sound";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const ClubsEventsUpload = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [currentServer, setCurrentServer] = useState(0);
  const [clubEventsInfo, setClubEventsInfo] = useState([]);
  const [assetUploaders, setAssetUploaders] = useState([]);

  const handleServerChange = (event) => {
    setCurrentServer(event.target.value);
  };

  useEffect(() => {
    if (assetUploaders.length == 0) {
      var assetUploader = [];
      assetUploader.push(<ClubsEventsUploader props={props} />);
      setAssetUploaders(assetUploader);
    }
  }, []);

  const publishAssets = () => {
    setLoading(true);
    let url = "/api/uploadClubEvents/dev/" + currentServer;
    axios
      .post(url, clubEventsInfo)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Club events Publish Failed", {
            variant: "error",
            persist: true,
            onClick: () => {
              closeSnackbar(key);
            },
          });
          playEventCompletionSound(false);
        } else {
          const key = enqueueSnackbar("Club Events Publish Successful", {
            variant: "success",
            persist: true,
            onClick: () => {
              closeSnackbar(key);
            },
          });
        }
        playEventCompletionSound(true);
        setLoading(false);
        setAssetUploaders([]);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Club Events Publish Failed", {
          variant: "error",
          persist: true,
          onClick: () => {
            closeSnackbar(key);
          },
        });
        playEventCompletionSound(false);
        setLoading(false);
      });
    setLoading(true);
  };
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Wrapper heading="Club Events Upload" setIsLoggedIn={props.setIsLoggedIn}>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.header}>
            <div style={{ flexGrow: 1 }}></div>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="server-select"
                id="server-select"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={currentServer}
                onChange={handleServerChange}
              >
                <MenuItem value={0}>Dev</MenuItem>
                <MenuItem value={1}>Production</MenuItem>
              </Select>
            </FormControl>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={publishAssets}
              disabled={loading}
            >
              Publish
              {loading && (
                <CircularProgress size={24} className={props.buttonProgress} />
              )}
            </Button>
          </div>
        </Container>
        {assetUploaders}
      </Wrapper>
    </div>
  );
};

ClubsEventsUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(ClubsEventsUpload, useStyles);
