import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { makeStyles } from "tss-react/mui";
import InputGroup from "./InputGroup";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import TreasureReward from "./TreasureReward";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";

const useStyles = makeStyles()((theme) => ({
  textField: {
    marginBottom: 15,
  },
  formControl: {
    marginBottom: 15,
  },
}));

export default function InputBox(props) {
  const { classes } = useStyles();

  const getTextField = () => {
    switch (props.option.type) {
      case "text":
        return (
          <TextField
            className={classes.textField}
            id="outlined-required"
            label={props.option.label}
            onChange={(event) => {
              props.handleChange(
                event.target.name,
                event.target.value,
                props.option.serverType
              );
            }}
            value={props.value}
            name={props.option.name}
          />
        );
      case "number":
        return (
          <TextField
            className={classes.textField}
            id="textfield-number"
            type="tel"
            label={props.option.label}
            onChange={(event) => {
              const value = parseInt(event.target.value);
              props.handleChange(
                event.target.name,
                Number.isNaN(value) ? 0 : value,
                props.option.serverType
              );
            }}
            value={props.value}
            name={props.option.name}
          />
        );
      case "select":
        return (
          <FormControl
            className={classes.formControl}
            style={{ minWidth: "50%" }}
          >
            <InputLabel id="select-id" style={{ width: "max-content" }}>
              {props.option.label}
            </InputLabel>
            <Select
              labelId="select-id"
              id="select"
              value={props.value}
              name={props.option.name}
              onChange={(event) => {
                props.handleChange(
                  event.target.name,
                  event.target.value,
                  props.option.serverType
                );
              }}
              label={props.option.label}
            >
              {props.option.values.map((optionText, index) => {
                return (
                  <MenuItem key={index} value={optionText}>
                    {props.option.valueLabels[index]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      case "check-box":
        return (
          <FormControlLabel
            className={classes.textField}
            control={
              <Checkbox
                label={props.option.label}
                checked={props.value}
                name={props.option.name}
                onChange={(event) => {
                  props.handleChange(
                    event.target.name,
                    event.target.checked,
                    props.option.serverType
                  );
                }}
                color="primary"
              />
            }
            label={props.option.label}
          />
        );
      case "time": {
        return (
          <DateTimePicker
            label={props.option.label}
            type="datetime-local"
            value={props.value ? dayjs(props.value) : undefined}
            className={classes.textField}
            name={props.option.name}
            onChange={(value) => {
              const valueDayJs =
                typeof value === "string" ? dayjs(value) : value;

              props.handleChange(
                props.option.name,
                valueDayJs && valueDayJs.toISOString(),
                props.option.serverType
              );
            }}
            InputLabelProps={{
              shrink: true,
            }}
            views={["day", "month", "year", "hours", "minutes"]}
          />
        );
      }
      case "group":
        return (
          <InputGroup
            option={props.option}
            value={props.value}
            handleChange={props.handleChange}
          />
        );
      case "treasure-reward":
        return (
          <TreasureReward
            option={props.option}
            value={props.value}
            handleChange={props.handleChange}
            addTreasureReward={props.addTreasureReward}
          />
        );
      default:
        return "";
    }
  };

  return getTextField();
}

InputBox.propTypes = {
  children: PropTypes.node,
};
