import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import {
  Container,
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import { PropTypes } from "prop-types";
import { useSnackbar } from "notistack";
import Wrapper from "../components/Wrapper";
import axios from "axios";
import ReactJson from "react-json-view";
import LiveConfigVersion from "../components/LiveConfigVersion";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  blobPaper: {
    padding: theme.spacing(2),
  },
});

const GetConfig = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState("dice");
  const [version, setVersion] = useState(0);
  const [currentServer, setcurrentServer] = useState(0);
  const [data, setData] = useState();

  const getConfig = () => {
    setLoading(true);
    let url =
      "/api/config/dev/" +
      currentServer +
      "/config/" +
      config +
      "/version/" +
      version;
    axios
      .get(url)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Get Config Failed", {
            variant: "error",

            onClick: () => {
              closeSnackbar(key);
            },
          });
        } else {
          const key = enqueueSnackbar("Get Config Successful", {
            variant: "success",

            onClick: () => {
              closeSnackbar(key);
            },
          });
        }
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Get Config Failed", {
          variant: "error",
          onClick: () => {
            closeSnackbar(key);
          },
        });
        setData({});
        setLoading(false);
      });
  };

  const handleServerChange = (event) => {
    setcurrentServer(event.target.value);
  };

  const handleConfigChange = (event) => {
    setConfig(event.target.value);
  };

  const handleVersionChange = (event) => {
    setVersion(event.target.value);
  };

  const downloadConfing = () => {
    let jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(data),
    )}`;
    let link = document.createElement("a");
    link.href = jsonString;
    link.download = "data.json";
    link.click();
  };

  const { classes } = props;

  return (
    <div className={classes.root}>
      <Wrapper heading="Get Config" setIsLoggedIn={props.setIsLoggedIn}>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.header}>
            <Typography color="textSecondary" variant="h5">
              Live Config Versions
            </Typography>
            <div style={{ flexGrow: 1 }}></div>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="server-select"
                id="server-select"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={props.currentLiveServer}
                onChange={props.handleLiveInfoChange}
              >
                <MenuItem value={0}>Production</MenuItem>
                <MenuItem value={1}>Staging</MenuItem>
                <MenuItem value={2}>Dev</MenuItem>
              </Select>
            </FormControl>
          </div>
          {props.liveLoading ? (
            <div className={classes.loaderWrapper}>
              <CircularProgress className={classes.loader} color="secondary" />
            </div>
          ) : (
            <LiveConfigVersion liveInfo={props.liveInfo} />
          )}
        </Container>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.header}>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-basic"
                label="Version"
                value={version}
                style={{ marginRight: 10 }}
                onChange={handleVersionChange}
                variant="outlined"
              />
            </form>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="server-select"
                id="server-select"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={config}
                style={{ marginRight: 10 }}
                onChange={handleConfigChange}
              >
                <MenuItem value={"newDice"}>New Dice</MenuItem>
                <MenuItem value={"newToken"}>New Token</MenuItem>
                <MenuItem value={"newFrame"}>New Frame</MenuItem>
                <MenuItem value={"dice"}>Dice</MenuItem>
                <MenuItem value={"token"}>Token</MenuItem>
                <MenuItem value={"avatar"}>Avatar</MenuItem>
                <MenuItem value={"board"}>Board</MenuItem>
                <MenuItem value={"frame"}>Frame</MenuItem>
                <MenuItem value={"seasonCollection"}>Season</MenuItem>
                <MenuItem value={"event"}>Event</MenuItem>
                <MenuItem value={"cardCollection"}>Card Collection</MenuItem>
                <MenuItem value={"clubsLeaderboardReward"}>
                  Club Leaderboard Rewards
                </MenuItem>
                <MenuItem value={"clubs"}>Club DP</MenuItem>
                <MenuItem value={"clubsGift"}>Club Gifts</MenuItem>
                <MenuItem value={"clubsCosmeticEntryEffect"}>
                  Club Entry Effect
                </MenuItem>
                <MenuItem value={"clubBackground"}>Club Background</MenuItem>
                <MenuItem value={"clubsCosmeticChatBubble"}>
                  Club Chat Bubble
                </MenuItem>
                <MenuItem value={"clubsCosmeticProfileSlider"}>
                  Club Profile Slider
                </MenuItem>
                <MenuItem value={"clubsCosmeticProfileFrame"}>
                  Clubs Profile Frames
                </MenuItem>
                <MenuItem value={"clubCardFrame"}>Club Card Frame</MenuItem>
                <MenuItem value={"clubsRoyals"}>Clubs User Profile</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="server-select"
                id="server-select"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={currentServer}
                onChange={handleServerChange}
              >
                <MenuItem value={0}>Dev</MenuItem>
                <MenuItem value={1}>Production</MenuItem>
              </Select>
            </FormControl>
            <div style={{ flexGrow: 1 }}></div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={getConfig}
              disabled={loading}
            >
              Get
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            &nbsp; &nbsp;
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={downloadConfing}
              disabled={loading || data == undefined}
            >
              Download
            </Button>
          </div>
          <br></br>
          <br></br>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.blobPaper}>
              <ReactJson
                collapsed={false}
                src={data}
                theme={"summerfruit"}
                iconStyle="square"
              />
            </Paper>
          </Grid>
        </Container>
      </Wrapper>
    </div>
  );
};

GetConfig.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(GetConfig, useStyles);
