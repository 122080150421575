import {
  Container,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { makeStyles, withStyles } from "tss-react/mui";
import DropBox from "./DropBox";

const NAME_PREFIX = "TOKEN_";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const TokenUploader = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { classes } = useStyles();

  const [files, setFiles] = useState([]);
  const [tokenID, setTokenID] = useState(props.tokenID);
  const [tokenName, setTokenName] = useState("");
  const [tokenType, setTokenType] = useState(0);
  const [loading, setLoading] = useState(false);

  const tokenFiles = [
    "token_top_yellow",
    "token_top_blue",
    "token_top_green",
    "token_top_red",
  ];

  const uploadFile = (file, type) => {
    if (tokenID == "") {
      const key = enqueueSnackbar("No Token Name", {
        variant: "error",
      });
      return;
    }

    if (tokenName == "") {
      const key = enqueueSnackbar("No Token Name", {
        variant: "error",
      });
      return;
    }

    let found = false;
    for (var fileName of tokenFiles) {
      if (file.name == fileName + "_" + tokenID + ".png") {
        found = true;
      }
    }

    if (!found) {
      const key = enqueueSnackbar("Upload a Valid File", {
        variant: "error",
        persist: true,
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return;
    }

    setLoading(true);
    const data = new FormData();

    data.append("file", file);
    let url = "/api/file/tokenID/" + tokenID;
    axios
      .post(url, data, {})
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
            variant: "error",
          });
        } else {
          const key = enqueueSnackbar("File - " + file.name + " uploaded", {
            variant: "success",
          });
          addFile(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
          variant: "error",
          persist: true,
          onClick: () => {
            closeSnackbar(key);
          },
        });
      });
  };

  const addFile = (file) => {
    const currFiles = files;
    currFiles.push(file);
    setFiles([...currFiles]);
    var tokenInfo = {
      TokenName: NAME_PREFIX + tokenName,
      TokenID: tokenID.toString(),
      TokenType: tokenType.toString(),
    };
    props.addTokenInfo(tokenInfo);
    checkAndEnableAddToken();
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadFile(file, type);
    }
  };

  const checkAndEnableAddToken = () => {
    if (tokenName != "" && tokenID != "" && files.length > 0) {
      props.setEnableAddToken(true);
    } else {
      props.setEnableAddToken(false);
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.header}>
        <form
          className={classes.root}
          style={{ marginRight: 10 }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Token ID"
            value={tokenID}
            onChange={(event) => {
              setTokenID(event.target.value);
              checkAndEnableAddToken();
            }}
            variant="outlined"
          />
        </form>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="outlined-basic"
            label="Token Name"
            value={tokenName}
            onChange={(event) => {
              setTokenName(event.target.value);
              checkAndEnableAddToken();
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ marginRight: 0 }}>
                  {NAME_PREFIX}
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </form>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="token-type"
            id="token-type"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            value={tokenType}
            onChange={(event) => {
              setTokenType(event.target.value);
              checkAndEnableAddToken();
            }}
          >
            <MenuItem value={0}>Event</MenuItem>
            <MenuItem value={1}>Season</MenuItem>
            <MenuItem value={2}>Card Collection</MenuItem>
          </Select>
        </FormControl>
      </div>
      <DropBox
        type="main"
        uploadedFiles={files}
        handleDropFile={handleDropFile}
      />
    </Container>
  );
};
export default withStyles(TokenUploader, useStyles);
