export const KtmEventDataList = [
  {
    label: "Basic Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Event ID",
        name: "EventID",
        type: "number",
      },
      {
        label: "EVC",
        name: "EVC",
        type: "number",
      },
      {
        label: "LOC",
        name: "LOC",
        type: "number",
      },
      {
        label: "Country",
        name: "CountryCohort",
        type: "text",
      },
      {
        label: "Payer",
        name: "PayerCohort",
        type: "select",
        values: [0, 1, 2],
        valueLabels: ["No Payer", "Payer", "Both"],
      },
      {
        label: "Created After",
        name: "CreatedAfterCohort",
        type: "time",
      },
      {
        label: "Start Date",
        name: "StartDate",
        type: "time",
      },
      {
        label: "End Date",
        name: "EndDate",
        type: "time",
      },
      {
        label: "Min Client Version",
        name: "MinClientVersion",
        type: "number",
      },
      {
        label: "Monster HP",
        name: "MonsterHp[0]",
        type: "number",
      },
      {
        label: "Doll Type",
        name: "DollType[0]",
        type: "number",
      },
    ],
  },
  {
    label: "Test Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Start Date",
        name: "TestDate.start_date",
        type: "time",
      },
      {
        label: "End Date",
        name: "TestDate.end_date",
        type: "time",
      },
    ],
  },
  {
    label: "Rewards",
    name: "Rewards[0]",
    type: "group",
    data: [
      {
        label: "Coins",
        name: "G",
        type: "number",
      },
      {
        label: "Potions",
        name: "P",
        type: "number",
      },
      {
        label: "Dice ID",
        name: "D",
        type: "number",
      },
      {
        label: "Booster",
        name: "B",
        type: "number",
      },
      {
        label: "Platinum Coins",
        name: "PC",
        type: "number",
      },
      {
        label: "Token",
        name: "TID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Board",
        name: "BID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Avatar",
        name: "AID",
        type: "text",
        serverType: "array",
      },
      {
        label: "Has Card Reward",
        name: "HCR",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
  {
    label: "Asset Details",
    name: "EventThemeInfo",
    type: "group",
    data: [
      {
        label: "Event Type",
        name: "Name",
        type: "select",
        values: [
          "aliens",
          "beat_the_heat",
          "boredom",
          "cyclops",
          "dino",
          "fix_the_car",
          "fly_the_ballon",
          "medusa",
          "minotaur",
          "paintball",
          "pinata",
          "samurai",
          "stop_the_robbery",
          "valentines_day",
          "werewolf",
        ],
        valueLabels: [
          "Aliens",
          "Beat The Heat",
          "Boredom",
          "Cyclops",
          "Dino",
          "Fix The Car",
          "Fly The Ballon",
          "Medusa",
          "Minotaur",
          "Paintball",
          "Pinata",
          "Samurai",
          "Stop The Robbery",
          "Valentines Day",
          "Warewolf",
        ],
      },
      {
        label: "Use default assets",
        name: "DefaultAsset",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
];

export const FootballEventDataList = [
  {
    label: "Basic Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Event ID",
        name: "EventID",
        type: "number",
      },
      {
        label: "EVC",
        name: "EVC",
        type: "number",
      },
      {
        label: "LOC",
        name: "LOC",
        type: "number",
      },
      {
        label: "Country",
        name: "CountryCohort",
        type: "text",
      },
      {
        label: "Payer",
        name: "PayerCohort",
        type: "select",
        values: [0, 1, 2],
        valueLabels: ["No Payer", "Payer", "Both"],
      },
      {
        label: "Create After",
        name: "CreatedAfterCohort",
        type: "time",
      },
      {
        label: "Start Date",
        name: "StartDate",
        type: "time",
      },
      {
        label: "End Date",
        name: "EndDate",
        type: "time",
      },
      {
        label: "Min Client Version",
        name: "MinClientVersion",
        type: "number",
      },
      {
        label: "Free Goal Count",
        name: "FreeGoalCount",
        type: "number",
      },
      {
        label: "Goal Level 1",
        name: "MonsterHp[0]",
        type: "number",
      },
      {
        label: "Doll Type 1",
        name: "DollType[0]",
        type: "number",
      },
      {
        label: "Goal Level 2",
        name: "MonsterHp[1]",
        type: "number",
      },
      {
        label: "Doll Type 2",
        name: "DollType[1]",
        type: "number",
      },
      {
        label: "Goal Level 3",
        name: "MonsterHp[2]",
        type: "number",
      },
      {
        label: "Doll Type 3",
        name: "DollType[2]",
        type: "number",
      },
    ],
  },
  {
    label: "Test Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Start Date",
        name: "TestDate.StartDate",
        type: "time",
      },
      {
        label: "End Date",
        name: "TestDate.EndDate",
        type: "time",
      },
    ],
  },
  {
    label: "Rewards 1",
    name: "Rewards[0]",
    type: "group",
    data: [
      {
        label: "Coins",
        name: "G",
        type: "number",
      },
      {
        label: "Potions",
        name: "P",
        type: "number",
      },
      {
        label: "Dice ID",
        name: "D",
        type: "number",
      },
      {
        label: "Booster",
        name: "B",
        type: "number",
      },
      {
        label: "Platinum Coins",
        name: "PC",
        type: "number",
      },
      {
        label: "Token",
        name: "TID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Board",
        name: "BID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Avatar",
        name: "AID",
        type: "text",
        serverType: "array",
      },
      {
        label: "Has Card Reward",
        name: "HCR",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
  {
    label: "Rewards 2",
    name: "Rewards[1]",
    type: "group",
    data: [
      {
        label: "Coins",
        name: "G",
        type: "number",
      },
      {
        label: "Potions",
        name: "P",
        type: "number",
      },
      {
        label: "Dice ID",
        name: "D",
        type: "number",
      },
      {
        label: "Booster",
        name: "B",
        type: "number",
      },
      {
        label: "Platinum Coins",
        name: "PC",
        type: "number",
      },
      {
        label: "Token",
        name: "TID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Board",
        name: "BID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Avatar",
        name: "AID",
        type: "text",
        serverType: "array",
      },
      {
        label: "Has Card Reward",
        name: "HCR",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
  {
    label: "Rewards 3",
    name: "Rewards[2]",
    type: "group",
    data: [
      {
        label: "Coins",
        name: "G",
        type: "number",
      },
      {
        label: "Potions",
        name: "P",
        type: "number",
      },
      {
        label: "Dice ID",
        name: "D",
        type: "number",
      },
      {
        label: "Booster",
        name: "B",
        type: "number",
      },
      {
        label: "Platinum Coins",
        name: "PC",
        type: "number",
      },
      {
        label: "Token",
        name: "TID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Board",
        name: "BID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Avatar",
        name: "AID",
        type: "text",
        serverType: "array",
      },
      {
        label: "Has Card Reward",
        name: "HCR",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
  {
    label: "Asset Details",
    name: "EventThemeInfo",
    type: "group",
    data: [
      {
        label: "Event Type",
        name: "Name",
        type: "select",
        values: [
          "aqua",
          "astro",
          "beach",
          "circus",
          "cookie",
          "fashion",
          "fiery",
          "flower",
          "gladiator",
          "ninja",
          "pharaoh",
          "ragnar",
          "vr",
          "wizard",
        ],
        valueLabels: [
          "Aqua",
          "Astro",
          "Beach",
          "Circus",
          "Cookie",
          "Fashion",
          "Fiery",
          "Flower",
          "Gladiator",
          "Ninja",
          "Pharaoh",
          "Ragnar",
          "VR",
          "Wizard",
        ],
      },
      {
        label: "Use default assets",
        name: "DefaultAsset",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
];

export const TreasureRoadEventDataList = [
  {
    label: "Basic Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Event ID",
        name: "EventID",
        type: "number",
      },
      {
        label: "EVC",
        name: "EVC",
        type: "number",
      },
      {
        label: "LOC",
        name: "LOC",
        type: "number",
      },
      {
        label: "Country",
        name: "CountryCohort",
        type: "text",
      },
      {
        label: "Payer",
        name: "PayerCohort",
        type: "select",
        values: [0, 1, 2],
        valueLabels: ["No Payer", "Payer", "Both"],
      },
      {
        label: "Min Client Version",
        name: "MinClientVersion",
        type: "number",
      },
      {
        label: "Created After",
        name: "CreatedAfterCohort",
        type: "time",
      },
      {
        label: "Start Date",
        name: "StartDate",
        type: "time",
      },
      {
        label: "End Date",
        name: "EndDate",
        type: "time",
      },
      {
        label: "Pumpkins/Dice",
        name: "MonsterHp[0]",
        type: "number",
      },
      {
        label: "Doll Type",
        name: "DollType[0]",
        type: "number",
      },
    ],
  },
  {
    label: "Test Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Start Date",
        name: "TestDate.StartDate",
        type: "time",
      },
      {
        label: "End Date",
        name: "TestDate.EndDate",
        type: "time",
      },
    ],
  },
  {
    label: "Rewards",
    name: "Rewards[0]",
    type: "group",
    data: [
      {
        label: "Coins",
        name: "G",
        type: "number",
      },
      {
        label: "Potions",
        name: "P",
        type: "number",
      },
      {
        label: "Dice ID",
        name: "D",
        type: "number",
      },
      {
        label: "Booster",
        name: "B",
        type: "number",
      },
      {
        label: "Platinum Coins",
        name: "PC",
        type: "number",
      },
      {
        label: "Token",
        name: "TID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Board",
        name: "BID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Avatar",
        name: "AID",
        type: "text",
        serverType: "array",
      },
      {
        label: "Has Card Reward",
        name: "HCR",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
  {
    label: "Treasure Rewards",
    name: "TreasureRoadRewards",
    type: "treasure-reward",
    data: [],
  },
  {
    label: "Asset Details",
    name: "EventThemeInfo",
    type: "group",
    data: [
      {
        label: "Event Type",
        name: "Name",
        type: "select",
        values: [
          "delicious_desserts",
          "mad_scientist",
          "salsa",
          "sherlock",
          "space",
          "tarzan",
          "toy",
        ],
        valueLabels: [
          "Delicious Desserts",
          "Mad Scientist",
          "Salsa",
          "Sherlock",
          "Space",
          "Tarzan",
          "Toy",
        ],
      },
      {
        label: "Use default assets",
        name: "DefaultAsset",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
];

export const KtmV4EventDataList = [
  {
    label: "Basic Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Event ID",
        name: "EventID",
        type: "number",
      },
      {
        label: "EVC",
        name: "EVC",
        type: "number",
      },
      {
        label: "LOC",
        name: "LOC",
        type: "number",
      },
      {
        label: "Country",
        name: "CountryCohort",
        type: "text",
      },
      {
        label: "Payer",
        name: "PayerCohort",
        type: "select",
        values: [0, 1, 2],
        valueLabels: ["No Payer", "Payer", "Both"],
      },
      {
        label: "Created After",
        name: "CreatedAfterCohort",
        type: "time",
      },
      {
        label: "Start Date",
        name: "StartDate",
        type: "time",
      },
      {
        label: "End Date",
        name: "EndDate",
        type: "time",
      },
      {
        label: "Min Client Version",
        name: "MinClientVersion",
        type: "number",
      },
      {
        label: "Monster HP",
        name: "MonsterHp[0]",
        type: "number",
      },
      {
        label: "Doll Type",
        name: "DollType[0]",
        type: "number",
      },
    ],
  },
  {
    label: "Test Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Start Date",
        name: "TestDate.start_date",
        type: "time",
      },
      {
        label: "End Date",
        name: "TestDate.end_date",
        type: "time",
      },
    ],
  },
  {
    label: "Free Tier 8",
    name: "Rewards[0]",
    type: "group",
    data: [
      {
        label: "Coins",
        name: "G",
        type: "number",
      },
      {
        label: "Potions",
        name: "P",
        type: "number",
      },
      {
        label: "Dice ID",
        name: "D",
        type: "number",
      },
      {
        label: "Booster",
        name: "B",
        type: "number",
      },
      {
        label: "Platinum Coins",
        name: "PC",
        type: "number",
      },
      {
        label: "Token",
        name: "TID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Board",
        name: "BID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Avatar",
        name: "AID",
        type: "text",
        serverType: "array",
      },
      {
        label: "Has Card Reward",
        name: "HCR",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
  {
    label: "Bonus Tier 8",
    name: "Rewards[1]",
    type: "group",
    data: [
      {
        label: "Coins",
        name: "G",
        type: "number",
      },
      {
        label: "Potions",
        name: "P",
        type: "number",
      },
      {
        label: "Dice ID",
        name: "D",
        type: "number",
      },
      {
        label: "Booster",
        name: "B",
        type: "number",
      },
      {
        label: "Platinum Coins",
        name: "PC",
        type: "number",
      },
      {
        label: "Token",
        name: "TID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Board",
        name: "BID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Avatar",
        name: "AID",
        type: "text",
        serverType: "array",
      },
      {
        label: "Has Card Reward",
        name: "HCR",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
  {
    label: "Asset Details",
    name: "EventThemeInfo",
    type: "group",
    data: [
      {
        label: "Event Type",
        name: "Name",
        type: "select",
        values: ["villain"],
        valueLabels: ["Defeat The Villain"],
      },
      {
        label: "Use default assets",
        name: "DefaultAsset",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
];

export const KtmV5EventDataList = [
  {
    label: "Basic Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Event ID",
        name: "EventID",
        type: "number",
      },
      {
        label: "EVC",
        name: "EVC",
        type: "number",
      },
      {
        label: "LOC",
        name: "LOC",
        type: "number",
      },
      {
        label: "Country",
        name: "CountryCohort",
        type: "text",
      },
      {
        label: "Payer",
        name: "PayerCohort",
        type: "select",
        values: [0, 1, 2],
        valueLabels: ["No Payer", "Payer", "Both"],
      },
      {
        label: "Created After",
        name: "CreatedAfterCohort",
        type: "time",
      },
      {
        label: "Start Date",
        name: "StartDate",
        type: "time",
      },
      {
        label: "End Date",
        name: "EndDate",
        type: "time",
      },
      {
        label: "Min Client Version",
        name: "MinClientVersion",
        type: "number",
      },
    ],
  },
  {
    label: "Test Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Start Date",
        name: "TestDate.start_date",
        type: "time",
      },
      {
        label: "End Date",
        name: "TestDate.end_date",
        type: "time",
      },
    ],
  },
  {
    label: "Cosmetic Reward",
    name: "CosmeticRewad",
    type: "group",
    data: [
      {
        label: "Dice ID",
        name: "D",
        type: "number",
      },
      {
        label: "Token",
        name: "TID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Board",
        name: "BID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Avatar",
        name: "AID",
        type: "text",
        serverType: "array",
      },
    ],
  },
  {
    label: "Card Cosmetic Reward",
    name: "CardCosmeticReward",
    type: "group",
    data: [
      {
        label: "Dice ID",
        name: "D",
        type: "number",
      },
      {
        label: "Token",
        name: "TID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Board",
        name: "BID",
        type: "number",
        serverType: "array",
      },
      {
        label: "Avatar",
        name: "AID",
        type: "text",
        serverType: "array",
      },
    ],
  },
  {
    label: "Asset Details",
    name: "EventThemeInfo",
    type: "group",
    data: [
      {
        label: "Event Type",
        name: "Name",
        type: "select",
        values: ["alien"],
        valueLabels: ["Defeat The Alien"],
      },
      {
        label: "Use default assets",
        name: "DefaultAsset",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
];

export const TreasureRoadV2EventDataList = [
  {
    label: "Basic Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Event ID",
        name: "EventID",
        type: "number",
      },
      {
        label: "EVC",
        name: "EVC",
        type: "number",
      },
      {
        label: "LOC",
        name: "LOC",
        type: "number",
      },
      {
        label: "Country",
        name: "CountryCohort",
        type: "text",
      },
      {
        label: "Payer",
        name: "PayerCohort",
        type: "select",
        values: [0, 1, 2],
        valueLabels: ["No Payer", "Payer", "Both"],
      },
      {
        label: "Created After",
        name: "CreatedAfterCohort",
        type: "time",
      },
      {
        label: "Start Date",
        name: "StartDate",
        type: "time",
      },
      {
        label: "End Date",
        name: "EndDate",
        type: "time",
      },
      {
        label: "Min Client Version",
        name: "MinClientVersion",
        type: "number",
      },
    ],
  },
  {
    label: "Test Info",
    name: "",
    type: "group",
    data: [
      {
        label: "Start Date",
        name: "TestDate.start_date",
        type: "time",
      },
      {
        label: "End Date",
        name: "TestDate.end_date",
        type: "time",
      },
    ],
  },
  {
    label: "Asset Details",
    name: "EventThemeInfo",
    type: "group",
    data: [
      {
        label: "Event Type",
        name: "Name",
        type: "select",
        values: [
          "delicious_desserts",
          "mad_scientist",
          "salsa",
          "sherlock",
          "space",
          "tarzan",
          "toy",
        ],
        valueLabels: [
          "Delicious Desserts",
          "Mad Scientist",
          "Salsa",
          "Sherlock",
          "Space",
          "Tarzan",
          "Toy",
        ],
      },
      {
        label: "Use default assets",
        name: "DefaultAsset",
        type: "select",
        values: [true, false],
        valueLabels: ["Yes", "No"],
      },
    ],
  },
];
