import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import Public from "@mui/icons-material/Public";
import Event from "@mui/icons-material/Event";
import Translate from "@mui/icons-material/GTranslate";
import NotificationsActive from "@mui/icons-material/NotificationsNone";
import Casino from "@mui/icons-material/Casino";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeveloperBoard from "@mui/icons-material/DeveloperBoard";
import Description from "@mui/icons-material/Description";
import FilterVintage from "@mui/icons-material/FilterVintage";
import Link from "@mui/icons-material/Link";
import FilterFramesIcon from "@mui/icons-material/FilterFrames";
import FaceIcon from "@mui/icons-material/Face";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import RedeemIcon from "@mui/icons-material/Redeem";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { GetAppType, AppType } from "../globals/conf.ts";
import AddCardIcon from "@mui/icons-material/AddCard";
import AddLinkIcon from "@mui/icons-material/AddLink";
import EventIcon from "@mui/icons-material/Event";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const useStyles = makeStyles()((theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    display: "flex",
    position: "relative",
    width: theme.spacing(8),
  },
  icon: {
    width: 35,
    height: 35,
  },
  listItem: {
    display: "flex",
    justifyContent: "center",
  },
}));

const sideList = [
  {
    name: "live",
    title: "Live",
    icon: (
      <Public
        style={{
          color: "inherit",
        }}
      ></Public>
    ),
  },
  {
    name: "events",
    title: "Event",
    icon: (
      <Event
        style={{
          color: "inherit",
        }}
      ></Event>
    ),
  },
  {
    name: "locale",
    title: "Localisation",
    icon: (
      <Translate
        style={{
          color: "inherit",
        }}
      ></Translate>
    ),
  },
  {
    name: "diceUpload",
    title: "Dice Upload",
    icon: (
      <Casino
        style={{
          color: "inherit",
        }}
      ></Casino>
    ),
  },
  {
    name: "boardUpload",
    title: "Board Upload",
    icon: (
      <DeveloperBoard
        style={{
          color: "inherit",
        }}
      ></DeveloperBoard>
    ),
  },
  {
    name: "tokenUpload",
    title: "Token Upload",
    icon: (
      <FiberManualRecordIcon
        style={{
          color: "inherit",
        }}
      ></FiberManualRecordIcon>
    ),
  },
  {
    name: "frameUpload",
    title: "Frame Upload",
    icon: (
      <FilterFramesIcon
        style={{
          color: "inherit",
        }}
      ></FilterFramesIcon>
    ),
  },
  {
    name: "avatarUpload",
    title: "Avatar Upload",
    icon: (
      <FaceIcon
        style={{
          color: "inherit",
        }}
      ></FaceIcon>
    ),
  },
  {
    name: "seasonUpload",
    title: "Season Upload",
    icon: (
      <FilterVintage
        style={{
          color: "inherit",
        }}
      ></FilterVintage>
    ),
  },
  {
    name: "cosmeticBundle",
    title: "Bundle Upload",
    icon: (
      <ShoppingCartCheckoutIcon
        style={{
          color: "inherit",
        }}
      ></ShoppingCartCheckoutIcon>
    ),
  },
  {
    name: "rewardBoxSeason",
    title: "RB Season Upload",
    icon: (
      <RedeemIcon
        style={{
          color: "inherit",
        }}
      ></RedeemIcon>
    ),
  },
  {
    name: "getConfig",
    title: "Get Config",
    icon: (
      <Description
        style={{
          color: "inherit",
        }}
      ></Description>
    ),
  },
  {
    name: "pushNotification",
    title: "Notification",
    icon: (
      <NotificationsActive
        style={{
          color: "inherit",
        }}
      ></NotificationsActive>
    ),
  },
  {
    name: "dynamicLink",
    title: "Dynamic Link",
    icon: (
      <Link
        style={{
          color: "inherit",
        }}
      ></Link>
    ),
  },
  {
    name: "configUpload",
    title: "Config Upload",
    icon: (
      <UploadFileIcon
        style={{
          color: "inherit",
        }}
      ></UploadFileIcon>
    ),
  },
];

if (GetAppType() == AppType.Ludo) {
  sideList.push(
    {
      name: "clubdpUpload",
      title: "Club Dp Upload",
      icon: (
        <AddPhotoAlternateIcon
          style={{
            color: "inherit",
          }}
        ></AddPhotoAlternateIcon>
      ),
    },
    {
      name: "clubLeaderboardRewardsScheduler",
      title: "Club Leaderboard Rewards",
      icon: <LeaderboardIcon style={{ color: "inherit" }}></LeaderboardIcon>,
    },
    {
      name: "clubEventsUpload",
      title: "Club Events Upload",
      icon: (
        <AddCardIcon
          style={{
            color: "inherit",
          }}
        ></AddCardIcon>
      ),
    },
    {
      name: "clubEventsWebLinkUpload",
      title: "Club Events WebLink Upload",
      icon: (
        <AddLinkIcon
          style={{
            color: "inherit",
          }}
        ></AddLinkIcon>
      ),
    },
    {
      name: "cosmeticAssetsUpload",
      title: "Cosmetic Assets Upload",
      icon: (
        <EventIcon
          style={{
            color: "inherit",
          }}
        ></EventIcon>
      ),
    },
    {
      name: "marketingBanner",
      title: "Marketing Banner Upload",
      icon: (
        <RedeemIcon
          style={{
            color: "inherit",
          }}
        ></RedeemIcon>
      ),
    }
  );
}

export default function SideBar(props) {
  const { classes } = useStyles();

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbarIcon}>
        <img className={classes.icon} alt="" src="icon.png"></img>
      </div>
      <Divider light={false} />
      <List>
        <div>
          {sideList.map((option) => (
            <Tooltip
              title={option.title}
              key={option.name}
              aria-label={option.name}
              placement="right"
            >
              <ListItem
                button
                className={classes.listItem}
                style={{
                  color:
                    props.currentView === option.name ? "#5da6d2" : "#cac9c9",
                }}
                onClick={() => {
                  props.setCurrentView(option.name);
                }}
              >
                {option.icon}
              </ListItem>
            </Tooltip>
          ))}
        </div>
      </List>
    </Drawer>
  );
}

SideBar.propTypes = {
  children: PropTypes.node,
};
