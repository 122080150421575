const enum ServerEventType {
  CannonGame,
  TreasureRoad = 2,
  MultiCannonGame,
  ktmV4 = 5,
  ktmV4Ludo,
}

const enum ServerSubEventType {
  cannon,
  football,
}

export const ServerEventTypes = new Map([
  [ServerEventType.CannonGame, "KTM Event"],
  [ServerEventType.MultiCannonGame, "Multi KTM Event"],
  [ServerEventType.TreasureRoad, "TreasureRoad Event"],
  [ServerEventType.ktmV4, "KTMV4 Event"],
  [ServerEventType.ktmV4Ludo, "KTMV4 Event"],
]);

export const ServerSubEventTypes = new Map([
  [ServerSubEventType.cannon, "Cannon"],
  [ServerSubEventType.football, "Football"],
]);

const enum EventNameTypes {
  ktm,
  ktmV3,
  football,
  treasureRoad,
  ktmV4,
}

export const EventNames = new Map([
  [EventNameTypes.ktm, "KTM Event"],
  [EventNameTypes.ktmV3, "KTMV3 Event"],
  [EventNameTypes.football, "Football Event"],
  [EventNameTypes.treasureRoad, "TreasureRoad Event"],
  [EventNameTypes.ktmV4, "KTMV4 Event"],
]);

/* For Club Leaderboard Assets*/

export const enum LeaderboardCategory {
  club,
  sender,
  receiver,
}

export const LeaderboardCategoryNames = [
  { value: LeaderboardCategory.club, id: "club", label: "Club" },
  { value: LeaderboardCategory.sender, id: "sender", label: "Gifter" },
  { value: LeaderboardCategory.receiver, id: "receiver", label: "Reciever" },
];

export const enum ClubRewards {
  backgrounds,
  card,
}

export const enum UserRewards {
  profileFrames,
  chatBubbles,
}

export const ClubRewardNames = [
  { value: ClubRewards.backgrounds, id: "backgrounds", label: "Background" },
  { value: ClubRewards.card, id: "cards", label: "Card Frame" },
];

export const UserRewardNames = [
  {
    value: UserRewards.profileFrames,
    id: "profileFrames",
    label: "Profile Frames",
  },
  { value: UserRewards.chatBubbles, id: "chatBubbles", label: "Chat Bubbles" },
];

export const RankList = [
  { value: 0, minRank: 1, maxRank: 1, label: "1", id: 1 },
  { value: 1, minRank: 2, maxRank: 2, label: "2", id: 2 },
  { value: 2, minRank: 3, maxRank: 3, label: "3", id: 3 },
];

export const EventWarnings = new Map([
  [
    "eventID already exists in staging",
    "Warning: EventId already exists in staging, do you still want to continue ?",
  ],
]);
