import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const useStyles = makeStyles()((theme) => ({
  dialog: {
    minWidth: 500,
    paddingBottom: theme.spacing(2),
    flexDirection: "column",
    display: "flex",
  },
}));

const devNames = ["Dev", "Staging", "Production"];

export default function PublishDialog(props) {
  const { classes } = useStyles();

  if (props.eventData == null) {
    return <div></div>;
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Publish Event</DialogTitle>
        <DialogContent className={classes.dialog}>
          Are you sure you want to publish {props.eventData.Name} to{" "}
          {devNames[props.activeReleaseIndex]}?<br></br>
        </DialogContent>
        <DialogContent className={classes.dialog} style={{ color: "red" }}>
          {props.warningString}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              props.handleClose();
              props.publishEventToServer(props.activeReleaseIndex);
            }}
            color="primary"
          >
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PublishDialog.propTypes = {
  children: PropTypes.node,
};
