import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Select, MenuItem } from "@mui/material";
import { GetAppType, AppType } from "../globals/conf.ts";

const useStyles = makeStyles()((theme) => ({
  dialog: {
    minWidth: 500,
    paddingBottom: theme.spacing(4),
    display: "flex",
  },
}));

export default function SingleFormDialog(props) {
  const { classes } = useStyles();
  const [newEvent, setNewEvent] = React.useState(null);
  const [eventType, setEventType] = React.useState(
    GetAppType() == AppType.Parchisi ? 0 : 1,
  );

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props.heading}</DialogTitle>
      <DialogContent className={classes.dialog}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          variant="outlined"
          label={props.label}
          type="text"
          onChange={(event) => setNewEvent(event.target.value)}
          text={newEvent}
        />
        <div style={{ flexGrow: 1 }}></div>
        <Select
          labelId="event-type"
          id="event-type"
          variant="outlined"
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          value={eventType}
          onChange={(event) => setEventType(event.target.value)}
        >
          {GetAppType() == AppType.Parchisi && (
            <MenuItem value={0}>KTM Event</MenuItem>
          )}
          {GetAppType() == AppType.Parchisi && (
            <MenuItem value={2}>Football Event</MenuItem>
          )}
          {GetAppType() == AppType.Ludo && (
            <MenuItem value={1}>KTMV3 Event</MenuItem>
          )}
          {GetAppType() == AppType.Parchisi && (
            <MenuItem value={5}>KTMV5 Event</MenuItem>
          )}
          {GetAppType() == AppType.Parchisi && (
            <MenuItem value={6}>Treasure Road V2</MenuItem>
          )}
          <MenuItem value={3}>Treasure Road</MenuItem>
          <MenuItem value={4}>KTMV4 Event</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            props.handleClose();
            props.handleAccept(newEvent, eventType);
          }}
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SingleFormDialog.propTypes = {
  children: PropTypes.node,
};
