import React, { useState } from "react";
import { withStyles } from "tss-react/mui";

import {
  Container,
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { PropTypes } from "prop-types";
import { useSnackbar } from "notistack";
import Wrapper from "../components/Wrapper";
import axios from "axios";
import { apiClient } from "../utils/apiClient";
import { EnvVars } from "../common/environment";
import { playEventCompletionSound } from "../utils/sound";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const Localisation = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentServer, setCurrentServer] = useState(0);

  const handleServerChange = (event) => {
    setCurrentServer(event.target.value);
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  const publishLocale = () => {
    setLoading(true);
    let url = "/api/uploadLocale/dev/" + currentServer;
    axios
      .post(url, {
        currentServer: currentServer,
        content: content,
        versionNumber: props.nextVersion,
      })
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("LOC Publish Failed. " + data.err, {
            variant: "error",
          });
          playEventCompletionSound(false);
        } else {
          const key = enqueueSnackbar("LOC Publish Successful", {
            variant: "success",
          });
          playEventCompletionSound(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar("LOC Publish Failed", {
          variant: "error",
        });
        playEventCompletionSound(false);
        setLoading(false);
      });
  };

  async function loadLocalFromSheet() {
    try {
      setLoading(true);

      const { data } = await apiClient.get("/api/loc/sheet");
      setContent(JSON.stringify(data, undefined, 4));

      enqueueSnackbar("LOC Load Success", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.response.data.err, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  const { classes } = props;
  return (
    <div className={classes.root}>
      <Wrapper heading="Localisation" setIsLoggedIn={props.setIsLoggedIn}>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.header}>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-basic"
                label="Version"
                disabled
                value={props.nextVersion}
                onChange={props.handleVersionChange}
                variant="outlined"
              />
            </form>

            {EnvVars.EnvSelectorDisabled ? (
              <></>
            ) : (
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="server-select"
                  id="server-select"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={currentServer}
                  onChange={handleServerChange}
                >
                  <MenuItem value={0}>Dev</MenuItem>
                  <MenuItem value={1}>Production</MenuItem>
                </Select>
              </FormControl>
            )}

            <div style={{ flexGrow: 1 }}></div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              style={{ marginRight: 10, color: "yellow" }}
              onClick={loadLocalFromSheet}
            >
              Load From Sheet
            </Button>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              style={{ marginRight: 10, color: "green" }}
              onClick={() => {
                window.open(
                  "https://docs.google.com/spreadsheets/d/1pWoWhAoumJ7m15Hfcdnm2tzBFHTi6tuQ8ynkw-mvrII/edit?ts=5cdd248d#gid=1274250112",
                  "_blank"
                );
              }}
            >
              Sheet
            </Button>
            <div className={classes.wrapper}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                disabled={loading}
                variant="outlined"
                size="large"
                onClick={publishLocale}
              >
                Publish
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>
          </div>
          <br></br>
          <br></br>
          <TextField
            id="outlined-multiline-static"
            multiline
            value={content}
            rows={15}
            onChange={handleContentChange}
            variant="outlined"
            fullWidth
            disabled
          />
        </Container>
      </Wrapper>
    </div>
  );
};

Localisation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Localisation, useStyles);
