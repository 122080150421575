import React, { useState, useEffect } from "react";
import Wrapper from "../components/Wrapper";
import { withStyles } from "tss-react/mui";
import {
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  Grid,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { PropTypes } from "prop-types";
import DropBox from "../components/DropBox";
import RewardDropDown from "../components/RewardDropDown";
import { EnvVars } from "../common/environment";
import { playEventCompletionSound } from "../utils/sound";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    margin: "5vh 10vw",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const BundleUpload = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [bundleID, setBundleID] = useState(0);
  const [bundleVariant, setBundleVariant] = useState(0);
  const [loading, setLoading] = useState(false);
  const [server, setServer] = useState(0);
  const [mainFiles, setMainFiles] = useState([]);
  const [spineHDFiles, setspineHDFiles] = useState([]);
  const [spineSDFiles, setspineSDFiles] = useState([]);
  const [spineLDFiles, setspineLDFiles] = useState([]);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [rewardData, setRewardData] = useState({ 0: {} });

  useEffect(() => {
    getBundleID();
  }, []);

  const getBundleID = () => {
    setLoading(true);
    axios
      .get("/api/cosmeticBundle/getBundleID")
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        setLoading(false);
        if (data.payload) {
          setBundleID(data["payload"]);
        }
      });
  };

  const checkValidity = (data) => {
    for (let i in data) {
      if (Object.keys(data[i]).length == 0) {
        return false;
      }
    }
    if (
      bundleID <= 0 ||
      mainFiles.length <= 0 ||
      startTime == Date() ||
      endTime == Date() ||
      bundleVariant == 0
    ) {
      return false;
    }
    return true;
  };

  const publishBundle = (data) => {
    let isDataValid = checkValidity(data);
    if (!isDataValid) {
      alert("Invalid or Missing Data");
      return;
    }
    setLoading(true);
    let url = `/api/publishBundle/dev/${server}/bundle/${bundleID}`;
    let cosmeticData = {
      variant: bundleVariant,
      bundleID: bundleID,
      startTime: startTime,
      endTime: endTime,
      rewardData: rewardData[0],
    };
    axios
      .post(url, cosmeticData)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Publish Failed", {
            variant: "error",
            autoHideDuration: 3000,
            maxSnack: 4,
          });
          playEventCompletionSound(false);
        } else {
          const key = enqueueSnackbar("Publish Successful", {
            variant: "success",
          });
          playEventCompletionSound(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Publish Failed", {
          variant: "error",
          autoHideDuration: 3000,
          maxSnack: 4,
        });
        playEventCompletionSound(false);
        setLoading(false);
      });
  };

  // Uploads bundle assets to the tool instance
  const uploadBundleAssets = (file, type) => {
    setLoading(true);
    const data = new FormData();
    data.append("file", file);
    let url = `/api/file/bundleAssets/${bundleID}/${type}`;
    axios
      .post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
            variant: "error",
            autoHideDuration: 3000,
            maxSnack: 4,
          });
        } else {
          const key = enqueueSnackbar("File - " + file.name + " uploaded", {
            variant: "success",
            autoHideDuration: 3000,
            maxSnack: 4,
          });
          if (type == "main") {
            setMainFiles((current) => [...current, data]);
          } else if (type == "hd") {
            setspineHDFiles((current) => [...current, data]);
          } else if (type == "sd") {
            setspineSDFiles((current) => [...current, data]);
          } else if (type == "ld") {
            setspineLDFiles((current) => [...current, data]);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
          variant: "error",
          autoHideDuration: 3000,
          maxSnack: 4,
        });
        setLoading(false);
      });
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadBundleAssets(file, type);
    }
  };

  const { classes } = props;

  return (
    <div className={classes.root}>
      <Wrapper
        heading="Cosmetic Bundle Upload"
        setIsLoggedIn={props.setIsLoggedIn}
      >
        <div style={{ margin: "5vh 10vw" }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={3}>
              <TextField
                id="outlined-basic"
                label="Bundle ID"
                value={bundleID}
                onChange={(event) => {
                  setBundleID(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              {EnvVars.EnvSelectorDisabled ? (
                <></>
              ) : (
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    labelId="server-select"
                    id="server-select"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={server}
                    onChange={(event) => {
                      setServer(event.target.value);
                    }}
                  >
                    <MenuItem value={0}>Dev</MenuItem>
                    <MenuItem value={1}>Production</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="variant-select"
                  id="variant-select"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={bundleVariant}
                  onChange={(event) => {
                    setBundleVariant(event.target.value);
                  }}
                >
                  <MenuItem value={1}>V1 (Normal)</MenuItem>
                  <MenuItem value={2}>V2 (Animated Dice)</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Button
                onClick={() => {
                  publishBundle();
                }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="outlined"
                size="large"
                disabled={loading}
              >
                Publish
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label={"Start Date"}
                type="datetime-local"
                value={startTime}
                className={classes.textField}
                onChange={(event) => {
                  setStartTime(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label={"End Date"}
                type="datetime-local"
                value={endTime}
                className={classes.textField}
                onChange={(event) => {
                  setEndTime(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={4}>
              <RewardDropDown
                header={"Reward:"}
                tier={0}
                rewardData={rewardData}
                setReward={setRewardData}
              />
            </Grid>
            <Grid item xs={12} sm={8} />

            <div>
              <br />
              <br />
              <small style={{ color: "#32CD32" }}>
                {bundleVariant == 1 ? (
                  <>
                    {`REQUIRED_FILES = ["bundle-{}.csb", "cosmeticLobbyNode-{}
                    .csb", "bundle-{}-spine.skel",
                    "cosmetic_close_btn_icon.png", "cta_cb_button_green.png",
                    "icon_cb_timer_center_lobby_new.png",
                    "icon_cb_timer_needle_lobby_new.png",
                    "icon_cb_timer_lobby_new.png", "lobby_icon_cb.png"]`}
                    <br />
                    <br />
                    {`RESOLUTION_WISE_REQUIRED_FILES = [ "bundle-{}-spine.atlas",
                    "bundle-{}-spine.png"]`}
                  </>
                ) : (
                  <>
                    {`REQUIRED_FILES = ["bundle-{}.csb", "cosmeticLobbyNode-{}
                    .csb", "bundle-{}-spine.skel", "cb_animated_dice_tag.png",
                    "lobby_icon_cb.png", "cb_animated_play_icon.png",
                    "cb_animated_play_icon_cta_9slice.png",
                    "cb_close_button_system_icon.png"]`}
                    <br />
                    <br />
                    {`RESOLUTION_WISE_REQUIRED_FILES = [ "bundle-{}-spine.atlas",
                    "bundle-{}-spine.png"]`}
                  </>
                )}
              </small>
            </div>

            <Grid item xs={12}>
              <h2>Cosmetic Bundle Assets: </h2>
              <DropBox
                type="main"
                uploadedFiles={mainFiles}
                handleDropFile={handleDropFile}
                loading={loading}
                setLoading={setLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <h2>Cosmetic Spine HD Assets: </h2>
              <DropBox
                type="hd"
                uploadedFiles={spineHDFiles}
                handleDropFile={handleDropFile}
                loading={loading}
                setLoading={setLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <h2>Cosmetic Spine SD Assets: </h2>
              <DropBox
                type="sd"
                uploadedFiles={spineSDFiles}
                handleDropFile={handleDropFile}
                loading={loading}
                setLoading={setLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <h2>Cosmetic Spine LD Assets: </h2>
              <DropBox
                type="ld"
                uploadedFiles={spineLDFiles}
                handleDropFile={handleDropFile}
                loading={loading}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>
        </div>
      </Wrapper>
    </div>
  );
};

BundleUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(BundleUpload, useStyles);
