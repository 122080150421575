import React, { useState } from "react";
import { withStyles } from "tss-react/mui";

import {
  Container,
  FormControl,
  Select,
  MenuItem,
  Button,
  Typography,
  TextField,
  CircularProgress,
  Grid,
  InputLabel,
  Tooltip,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { PropTypes } from "prop-types";
import { useSnackbar } from "notistack";
import Wrapper from "../components/Wrapper";
import axios from "axios";
import DropBox from "../components/DropBox";
import InfoIcon from "@mui/icons-material/Info";
import InputAdornment from "@mui/material/InputAdornment";
import { EnvVars } from "../common/environment";
import { playEventCompletionSound } from "../utils/sound";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const theme = createTheme({
  typography: {
    toolTipHeader: {
      color: "orange",
    },
    toolTipMessage: {
      color: "white",
    },
  },
});

function getToolTipLine(left, right) {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography variant="toolTipHeader">{left}</Typography>
        <Typography variant="toolTipMessage">{right}</Typography>
        <br></br>
      </ThemeProvider>
    </div>
  );
}

const BoardUpload = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [BoardName, setBoardName] = useState("");
  const [boardType, setBoardType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentServer, setCurrentServer] = useState(0);
  const [files, setFiles] = useState([]);
  const [hdFiles, setHdFiles] = useState([]);
  const [sdFiles, setSdFiles] = useState([]);
  const [ldFiles, setLdFiles] = useState([]);

  const handleBoardNameChange = (event) => {
    setBoardName(event.target.value);
  };

  const handleServerChange = (event) => {
    setCurrentServer(event.target.value);
  };

  const handleBoardTypeChange = (event) => {
    setBoardType(event.target.value);
  };

  const publishBoard = () => {
    if (BoardName == "") {
      const key = enqueueSnackbar("No Board Name", {
        variant: "error",
      });
      return;
    }

    var isAllFilesUploaded = false;
    var errMsg = "No Files to Upload";
    if (boardType == 0) {
      isAllFilesUploaded = files.length > 0;
    } else {
      isAllFilesUploaded =
        hdFiles.length > 0 && sdFiles.length > 0 && ldFiles.length > 0;
      errMsg = "All files not uploaded";
    }

    if (!isAllFilesUploaded) {
      const key = enqueueSnackbar(errMsg, {
        variant: "error",
      });
      return;
    }

    setLoading(true);

    let state = {};
    state["BoardID"] = BoardName.toLowerCase() + "_board";
    state["BoardName"] = "BOARD_" + BoardName.toUpperCase();
    let url =
      "/api/uploadBoard/dev/" +
      currentServer +
      "/boardType/" +
      boardType.toString();
    axios
      .post(url, state)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Board Publish Failed", {
            variant: "error",
          });
          playEventCompletionSound(false);
        } else {
          const key = enqueueSnackbar("Board Publish Successful", {
            variant: "success",
          });
          playEventCompletionSound(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Board Publish Failed", {
          variant: "error",
        });
        playEventCompletionSound(false);
        setLoading(false);
      });
  };

  const uploadFile = (file, type) => {
    if (BoardName == "") {
      const key = enqueueSnackbar("No Board Name", {
        variant: "error",
      });
      return;
    }

    setLoading(true);

    const data = new FormData();
    data.append("file", file);
    let url =
      "/api/file/boardType/" +
      type +
      "/boardID/" +
      BoardName.toLowerCase() +
      "_board";
    axios
      .post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
            variant: "error",
          });
        } else {
          const key = enqueueSnackbar("File - " + file.name + " uploaded", {
            variant: "success",
          });
          addFile(data, type);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
          variant: "error",
        });
      });
  };

  const addFile = (file, type) => {
    if (type == "0") {
      setFiles((current) => [...current, file]);
    } else if (type == "1") {
      setHdFiles((current) => [...current, file]);
    } else if (type == "2") {
      setSdFiles((current) => [...current, file]);
    } else if (type == "3") {
      setLdFiles((current) => [...current, file]);
    }
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadFile(file, type);
    }
  };

  const { classes } = props;
  return (
    <div className={classes.root}>
      <Wrapper heading="Board Upload" setIsLoggedIn={props.setIsLoggedIn}>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.header}>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-basic"
                label="Board Name"
                value={BoardName}
                onChange={handleBoardNameChange}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" style={{ marginRight: 0 }}>
                      BOARD_
                    </InputAdornment>
                  ),
                }}
              />
            </form>

            {EnvVars.EnvSelectorDisabled ? (
              <></>
            ) : (
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="server-select"
                  id="server-select"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={currentServer}
                  onChange={handleServerChange}
                >
                  <MenuItem value={0}>Dev</MenuItem>
                  <MenuItem value={1}>Production</MenuItem>
                </Select>
              </FormControl>
            )}

            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="boardtype-select"
                id="boardtype-select"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={boardType}
                onChange={handleBoardTypeChange}
              >
                <MenuItem value={0}>Normal</MenuItem>
                <MenuItem value={1}>Animated</MenuItem>
              </Select>
            </FormControl>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Board Upload File Info
                  </Typography>
                  {getToolTipLine(
                    "Filename:",
                    "	(name)_board_(colour)_hole.png"
                  )}
                  {getToolTipLine(
                    "",
                    "Where colour is one of red, blue, green , yellow"
                  )}
                  {getToolTipLine("Number:", "	4")}
                  {getToolTipLine("Filename:", "	(name)_board_poster.png")}
                  {getToolTipLine("Number:", "	1")}
                  {getToolTipLine("Filename:", "	(name)_board.png")}
                  {getToolTipLine("Number:", "	1")}
                </React.Fragment>
              }
            >
              <InfoIcon sx={{ fontSize: 40 }}></InfoIcon>
            </Tooltip>
            <div style={{ flexGrow: 1 }}></div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={publishBoard}
              disabled={loading}
            >
              Publish
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </div>
          {boardType == 0 && (
            <DropBox
              type="0"
              uploadedFiles={files}
              handleDropFile={handleDropFile}
            />
          )}
          {boardType > 0 && (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <InputLabel id="label">HD files</InputLabel>
              </Grid>
              <Grid item xs={12}>
                <DropBox
                  type="1"
                  uploadedFiles={hdFiles}
                  handleDropFile={handleDropFile}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="label">SD files</InputLabel>
              </Grid>
              <Grid item xs={12}>
                <DropBox
                  type="2"
                  uploadedFiles={sdFiles}
                  handleDropFile={handleDropFile}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="label">LD files</InputLabel>
              </Grid>
              <Grid item xs={12}>
                <DropBox
                  type="3"
                  uploadedFiles={ldFiles}
                  handleDropFile={handleDropFile}
                />
              </Grid>
            </Grid>
          )}
        </Container>
      </Wrapper>
    </div>
  );
};

BoardUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(BoardUpload, useStyles);
