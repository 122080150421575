import React from "react";
import { withStyles } from "tss-react/mui";

import {
  IconButton,
  Container,
  CircularProgress,
  Menu,
  MenuItem,
  Backdrop,
  LinearProgress,
  Grid,
  Paper,
} from "@mui/material";

import { Component } from "react";
import { PropTypes } from "prop-types";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Wrapper from "../components/Wrapper";
import Chart from "react-apexcharts";

const useStyles = (theme) => ({
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  root: {
    width: "80%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  headingBar: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  backdrop: {
    flexDirection: "column",
    display: "flex",
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loaderWrapper: {
    display: "flex",
    marginTop: theme.spacing(10),
    justifyContent: "center",
  },
  blobPaper: {
    // padding: theme.spacing(2),
  },
  headingWrapper: {
    borderTopLeftRadius: "inherit",
    borderTopRightRadius: "inherit",
    // backgroundColor: "#098c00",
    padding: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    borderBottom: "1px solid #37474f47",
  },
  ContentWrapper: {
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
  expNumber: {
    width: "45px",
    textAlign: "center",
    padding: "5px",
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(15),
    color: "#37474f",
  },
});

class Cohort extends Component {
  state = {
    expanded: "",
    panelIndex: 0,
    open: false,
    openFull: false,
    publishOpen: false,
    activeReleaseIndex: 0,
    loading: false,
    stage: "",
    percent: 0,
    anchorEl: null,
    series: [44, 55, 13, 43, 22],
    options: {
      colors: [
        "#488f31",
        "#83af70",
        "#bad0af",
        "#f1f1f1",
        "#f0b8b8",
        "#e67f83",
        "#de425b",
      ],
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "top",
            },
          },
        },
      ],
    },
  };

  handleClickOpen = () => {
    this.setState({ open: true, newEvent: "" });
  };

  handleChange = (panel) => (event, isExpanded) => {
    if (this.state.expanded === panel) {
      this.setState({ expanded: -1 });
    } else {
      this.setState({ expanded: panel });
    }
  };

  removeDraft = (index) => {
    let state = Object.assign({}, this.state);
    state.drafts.splice(index, 1);
    state.expanded = "";
    this.setState(state);
  };

  getCohorts = () => {
    const { classes } = this.props;
    if (this.props.cohortLoading) {
      return (
        <div className={classes.loaderWrapper}>
          <CircularProgress className={classes.loader} color="secondary" />
        </div>
      );
    }

    var eventDrafts = [];
    if (this.props.cohortData) {
      for (var key in this.props.cohortData) {
        var option = this.props.cohortData[key];
        eventDrafts.push(
          <Grid item xs={12} lg={6}>
            <Paper className={classes.blobPaper}>
              <div className={classes.headingWrapper}>
                <Typography className={classes.heading}>
                  {"Experment Name"}
                </Typography>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <Typography className={classes.expNumber}>
                    {"#" + key}
                  </Typography>
                  <Button
                    variant="outlined"
                    style={{ height: "fit-content" }}
                    onClick={() => this.setState({ openFull: true })}
                    color="primary"
                  >
                    Edit
                  </Button>
                </div>
              </div>

              <div className={classes.ContentWrapper}>
                <Chart
                  options={this.state.options}
                  series={this.state.series}
                  type="pie"
                  width={380}
                />
              </div>
            </Paper>
          </Grid>,
        );
      }
    }

    return eventDrafts;
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Wrapper
          heading="Experiment Cohorts"
          setIsLoggedIn={this.props.setIsLoggedIn}
        >
          <Container maxWidth="lg" className={classes.container}>
            {/* <div className={classes.header}>
              <Typography color="textSecondary" variant="h5">
                Cohorts
              </Typography>
              <div style={{ flexGrow: 1 }}></div>
              <IconButton
                onClick={() => {
                  this.handleClickOpen();
                }}
              >
                <AddCircle color="primary" fontSize="large"></AddCircle>
              </IconButton>
            </div> */}
            <Grid container spacing={2}>
              {this.getCohorts()}
            </Grid>
          </Container>
        </Wrapper>
        {/* <SingleFormDialog
          open={this.state.open}
          heading="New Event Draft"
          label="Name"
          handleAccept={this.props.addEventDraft}
          handleClose={() => this.setState({ open: false })}
        /> */}
        {/* <PublishDialog
          publishEventToServer={this.publishEventToServer}
          open={this.state.publishOpen}
          eventData={
            this.props.user.EventDrafts
              ? this.props.user.EventDrafts[this.state.panelIndex]
              : null
          }
          handleChange={this.props.updateEvent}
          activeReleaseIndex={this.state.activeReleaseIndex}
          handleClose={() => this.setState({ publishOpen: false })}
        /> */}
        {/* <EventEditor
          open={this.state.openFull}
          updateEvent={this.props.updateEvent}
          setUserData={this.props.setUserData}
          addFile={this.props.addFile}
          event={
            this.props.user.EventDrafts
              ? this.props.user.EventDrafts[this.state.panelIndex]
              : null
          }
          handleClose={() => this.setState({ openFull: false })}
        /> */}
        <Backdrop className={classes.backdrop} open={this.state.loading}>
          <Typography variant="h5">{this.state.stage}</Typography>
          <br></br>
          <br></br>
          <div className={classes.root}>
            <LinearProgress variant="determinate" value={this.state.percent} />
          </div>
        </Backdrop>
      </div>
    );
  }
}

Cohort.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Cohort, useStyles);
