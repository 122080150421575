import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Container } from "@mui/material";
import DropBox from "./DropBox";
import { useSnackbar } from "notistack";
import { withStyles } from "tss-react/mui";
import axios from "axios";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ClubDpsUploader = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { classes } = useStyles();

  const [files, setFiles] = useState([]);

  const uploadFile = (file, type) => {
    const data = new FormData();
    data.append("file", file);
    let url = "/api/file/clubdp/";
    axios
      .post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("File - " + file.name + " not uploaded. " + data.err, {
            variant: "error",
            persist: true,
            onClick: () => {
              this.closeSnackbar(key);
            },
          });
          const handleSnackbarClick = () => {
            closeSnackbar(key);
          };
          setTimeout(handleSnackbarClick, 5000);
        } else {
          const key = enqueueSnackbar("File - " + file.name + " uploaded", {
            variant: "success",
            persist: true,
            onClick: () => {
              this.closeSnackbar(key);
            },
          });
          const handleSnackbarClick = () => {
            closeSnackbar(key);
          };
          setTimeout(handleSnackbarClick, 5000);
          addFile(data);
        }
      })
      .catch((err) => {
        console.log(err);
        const key = enqueueSnackbar("File - " + file.name + " not uploaded. " + err, {
          variant: "error",
          persist: true,
        });
        const handleSnackbarClick = () => {
          closeSnackbar(key);
        };
        setTimeout(handleSnackbarClick, 5000);
      });
  };

  const addFile = (file) => {
    const currFiles = files;
    currFiles.push(file);
    setFiles([...currFiles]);
    checkAndEnableAddDp();
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadFile(file, type);
    }
  };

  const checkAndEnableAddDp = () => {
    if (files.length > 0) {
      props.setEnableAddDp(true);
    } else {
      props.setEnableAddDp(false);
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <DropBox
        type="main"
        uploadedFiles={files}
        handleDropFile={handleDropFile}
      />
    </Container>
  );
};
export default withStyles(ClubDpsUploader, useStyles);
