import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

const useStyles = makeStyles({
  shakeAnimation: {
    animation: '$shake 0.5s',
  },
  '@keyframes shake': {
    '0%': { transform: 'translateX(0)' },
    '25%': { transform: 'translateX(-5px)' },
    '50%': { transform: 'translateX(5px)' },
    '75%': { transform: 'translateX(-5px)' },
    '100%': { transform: 'translateX(0)' },
  },
});

export default function ConfirmDialog({ open, onClose, dialogContentText }) {
  const classes = useStyles();
  const [confirmation, setConfirmation] = React.useState('');

  const handleClose = () => {
    if (onClose) onClose(false);
  };

  const handleSubmit = () => {
    if (confirmation.trim().toLowerCase() === 'confirm') {
      if (onClose) onClose(true);
    } else {
      const dialogWindow = document.querySelector('.MuiDialog-paper');
      if (dialogWindow) {
        dialogWindow.classList.add(classes.shakeAnimation);
        setTimeout(() => {
          dialogWindow.classList.remove(classes.shakeAnimation);
        }, 500);
      }
    }
  };

  const handleDialogClose = () => {
    setConfirmation('');
    handleClose();
  };

  const renderDialogContentText = () => {
    if (!dialogContentText) return 'Are you sure you want to proceed?';
    return dialogContentText.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {renderDialogContentText()}
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="confirmation"
          label="Type 'confirm' to confirm this action."
          type="text"
          fullWidth
          variant="standard"
          value={confirmation}
          onChange={(e) => setConfirmation(e.target.value)}
          autoComplete="off"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Cancel</Button>
        <Button onClick={handleSubmit} style={{ color: confirmation.trim().toLowerCase() === 'confirm' ? '' : 'inherit' }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
