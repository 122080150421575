import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Select,
  MenuItem,
  unsupportedProp,
  CircularProgress,
} from "@mui/material";
import {
  LeaderboardCategoryNames,
  UserRewardNames,
  ClubRewardNames,
  LeaderboardCategory,
  RankList,
} from "../../../../constants/constants.ts";

const useStyles = (theme) => ({
  dialog: {
    minWidth: 500,
    paddingBottom: theme.spacing(4),
    display: "flex",
  },
  dropdown: {
    marginRight: 10,
  },
});

function AssignAssetPopup(props) {
  const { classes } = props;

  const [assetCategory, setAssetCategory] = useState(0);
  const [assetList, setAssetList] = useState(ClubRewardNames);
  const [assetType, setAssetType] = useState(0);
  const [rankRange, setRankRange] = useState(0);
  const [assetId, setAssetId] = useState("");
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleCategorySelect = (category) => {
    setAssetCategory(category);
    if (category == LeaderboardCategory.club) {
      setAssetList(ClubRewardNames);
    } else {
      setAssetList(UserRewardNames);
    }
    setAssetType(0);
  };

  const _validForm = () => {
    return (
      assetId.trim() != "" &&
      assetCategory != undefined &&
      assetType != undefined
    );
  };

  const resetFields = () => {
    setAssetCategory(0);
    setAssetList(ClubRewardNames);
    setAssetType(0);
    setAssetId("");
    setRankRange(0);
    setIsFormInvalid(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props.heading}</DialogTitle>
      <DialogContent className={classes.dialog}>
        <Select
          labelId="category-selector"
          id="category-selector"
          variant="outlined"
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          value={assetCategory}
          onChange={(category) => handleCategorySelect(category.target.value)}
          className={classes.dropdown}
        >
          {LeaderboardCategoryNames.map(({ value, id, label }) => (
            <MenuItem value={value} key={id}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <Select
          labelId="asset-type-selector"
          id="asset-type-selector"
          variant="outlined"
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          value={assetType}
          onChange={(assetType) => setAssetType(assetType.target.value)}
          className={classes.dropdown}
        >
          {assetList.map(({ value, id, label }) => (
            <MenuItem value={value} key={id}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <Select
          labelId="rank-selector"
          id="rank-selector"
          variant="outlined"
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          value={rankRange}
          onChange={(rankRange) => {
            setRankRange(rankRange.target.value);
          }}
          className={classes.dropdown}
        >
          {RankList.map(({ value, id, label }) => (
            <MenuItem value={value} key={id}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <TextField
          margin="dense"
          id="assetId"
          variant="outlined"
          label={props.label}
          type="text"
          onChange={(assetId) => setAssetId(assetId.target.value)}
          error={isFormInvalid}
          text={assetId}
          helperText={isFormInvalid && "Asset ID can't be empty"}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!_validForm()) {
              setIsFormInvalid(true);
              return;
            }
            setIsUploading(true);
            props
              .handleAccept({
                assetId: assetId,
                assetCategory: LeaderboardCategoryNames[assetCategory].label,
                assetType: assetList[assetType].label,
                minRank: RankList[rankRange].minRank,
                maxRank: RankList[rankRange].maxRank,
                assetCategoryId: LeaderboardCategoryNames[assetCategory].id,
                assetTypeId: assetList[assetType].id,
              })
              .then(() => {
                setIsUploading(false);
              })
              .then((_) => {
                resetFields();
                props.handleClose();
              });
          }}
          color="primary"
        >
          {isUploading ? <CircularProgress /> : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AssignAssetPopup.propTypes = {
  children: PropTypes.node,
};

export default withStyles(AssignAssetPopup, useStyles);
