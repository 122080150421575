import React, { useState, useEffect, version } from "react";
import ReactDiffViewer from "react-diff-viewer";

import { withStyles } from "tss-react/mui";

import {
  Container,
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { PropTypes } from "prop-types";
import { useSnackbar } from "notistack";
import Wrapper from "../components/Wrapper";
import axios from "axios";
import { GetConfigUploadList } from "../data/GetData";
import { EnvVars } from "../common/environment";
import { playEventCompletionSound } from "../utils/sound";

var sortobject = require("deep-sort-object");

const buttonStateEnum = Object.freeze({
  COMPARE: "Compare",
  EDIT: "Edit",
});

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const ConfigUploader = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [content, setContent] = useState("");
  const [publishedContent, setPublishedContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState("");
  const [configVersion, setConfigVersion] = useState(0);
  const [currentServer, setCurrentServer] = useState(0);
  const [buttonState, setButtonState] = useState(buttonStateEnum.COMPARE);

  useEffect(() => {
    handleConfigChange("DCE");
  }, []);

  const handleServerChange = (event) => {
    setCurrentServer(event.target.value);
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  const handleConfigChange = (value) => {
    setConfig(value);
    setLoading(true);
    axios
      .get("/api/configData/latestConfigVersion/" + value)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("" + data.err, {
            variant: "error",
          });
        } else {
          setConfigVersion(data[value]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleVersionChange = (event) => {
    setConfigVersion(Number(event.target.value));
  };

  const handlePublishClick = () => {
    let confirm = window.confirm(
      "Are you sure you want to publish this config!"
    );
    if (confirm) {
      publishConfig();
    }
  };

  const publishConfig = () => {
    setLoading(true);
    let url = "/api/publishConfig/" + currentServer;
    axios
      .post(url, {
        currentServer: currentServer,
        content: content,
        versionNumber: configVersion,
        configCode: config,
      })
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Config Publish Failed: " + data.err, {
            variant: "error",
          });
          playEventCompletionSound(false);
        } else {
          const key = enqueueSnackbar("Config Publish Successful", {
            variant: "success",
          });
          playEventCompletionSound(true);
          setButtonState(buttonStateEnum.COMPARE);
          setContent("");
          setConfig("DCE");
          handleConfigChange("DCE");
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Config Publish Failed: " + err, {
          variant: "error",
        });
        playEventCompletionSound(false);
        setLoading(false);
      });
  };

  const compareConfig = () => {
    if (buttonState == buttonStateEnum.EDIT) {
      setButtonState(buttonStateEnum.COMPARE);
      return;
    }
    setButtonState(buttonStateEnum.EDIT);
    setLoading(true);
    let url = "/api/configData/getLatestConfig/" + config;
    axios
      .get(url)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Prod Config Fetch Failed: " + err, {
            variant: "error",
          });
          setButtonState(buttonStateEnum.COMPARE);
        } else {
          data = sortobject(data);
          var text = JSON.stringify(data, undefined, 4);
          setPublishedContent(text);

          var contentObject = JSON.parse(content);
          contentObject = sortobject(contentObject);
          var contentText = JSON.stringify(contentObject, undefined, 4);
          setContent(contentText);

          const key = enqueueSnackbar("Prod Config Fetch Successful", {
            variant: "success",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("" + err, {
          variant: "error",
        });
        setPublishedContent("");
        setLoading(false);
        setButtonState(buttonStateEnum.COMPARE);
      });
  };

  const { classes } = props;
  const configListItems = GetConfigUploadList().map((item) => (
    <MenuItem value={item.value} key={item.value}>
      {item.label}
    </MenuItem>
  ));

  return (
    <div className={classes.root}>
      <Wrapper heading="Config Upload" setIsLoggedIn={props.setIsLoggedIn}>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.header}>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-basic"
                label="Version"
                value={configVersion}
                variant="outlined"
                onChange={handleVersionChange}
                disabled={loading || buttonState == buttonStateEnum.EDIT}
                type="number"
              />
            </form>

            {EnvVars.EnvSelectorDisabled ? (
              <></>
            ) : (
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="server-select"
                  id="server-select"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={currentServer}
                  onChange={handleServerChange}
                  disabled={loading || buttonState == buttonStateEnum.EDIT}
                >
                  <MenuItem value={0}>Dev</MenuItem>
                  <MenuItem value={1}>Production</MenuItem>
                </Select>
              </FormControl>
            )}

            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="server-select"
                id="server-select"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={config}
                style={{ marginRight: 10 }}
                onChange={(event) => {
                  handleConfigChange(event.target.value);
                }}
                disabled={loading || buttonState == buttonStateEnum.EDIT}
              >
                {configListItems}
              </Select>
            </FormControl>
            <div style={{ flexGrow: 1 }}></div>
            <div className={classes.wrapper}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                disabled={loading}
                variant="outlined"
                size="large"
                onClick={compareConfig}
              >
                {buttonState.toString()}
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                disabled={loading || buttonState == buttonStateEnum.COMPARE}
                variant="outlined"
                size="large"
                onClick={handlePublishClick}
              >
                Publish
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>
          </div>
          <br></br>
          <br></br>
          {buttonState == buttonStateEnum.COMPARE && (
            <TextField
              id="outlined-multiline-static"
              multiline
              value={content}
              rows={25}
              onChange={handleContentChange}
              variant="outlined"
              fullWidth
            />
          )}
          {buttonState == buttonStateEnum.EDIT && !loading && (
            <ReactDiffViewer
              oldValue={publishedContent}
              newValue={content}
              splitView={true}
              showDiffOnly={true}
              useDarkTheme={true}
            />
          )}
        </Container>
      </Wrapper>
    </div>
  );
};

ConfigUploader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(ConfigUploader, useStyles);
