import React from "react";
import { withStyles } from "tss-react/mui";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

const useStyles = (theme) => ({
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  root: {
    width: "80%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexGrow: 1,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  headingBar: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  backdrop: {
    flexDirection: "column",
    display: "flex",
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loaderWrapper: {
    display: "flex",
    marginTop: theme.spacing(10),
    justifyContent: "center",
  },
  cardDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardEnd: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 15,
  },
});

function _HeadingAndBody({ heading, body, classes }) {
  return (
    <div style={{ flexGrow: 1 }}>
      <Typography className={classes.heading}>{heading}</Typography>
      <Typography className={classes.secondaryHeading}>{body}</Typography>
    </div>
  );
}

function _LabelText({ label, text, classes }) {
  return (
    <div style={{ marginLeft: 10 }}>
      <span style={{ fontWeight: "bold" }}>{label}</span>
      {" : " + text}
    </div>
  );
}

function CollapsableCard(props) {
  const { classes } = props;

  const [isExpanded, setExpanded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  return (
    <Accordion
      key={props.id}
      expanded={isExpanded}
      onChange={(expand) => {
        setExpanded(!isExpanded);
      }}
    >
      <AccordionSummary
        className={classes.headingBar}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <_HeadingAndBody heading={props.assetCategory} classes={classes} />
        <_HeadingAndBody heading={props.assetType} classes={classes} />
        <_HeadingAndBody
          heading="Rank"
          body={props.minRank + "-" + props.maxRank}
          classes={classes}
        />
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.cardDetails}>
          <_LabelText
            label={"Asset Id"}
            text={props.assetId}
            classes={classes}
          />
          <_LabelText
            label={"Min Rank"}
            text={props.minRank}
            classes={classes}
          />
          <_LabelText
            label={"Max Rank"}
            text={props.maxRank}
            classes={classes}
          />
        </div>
        <div className={classes.cardEnd}>
          <Button
            variant="outlined"
            onClick={() => {
              setIsUploading(true);
              props.removeClubRewardScheduleDraft(props.id).then((_) => {
                setIsUploading(false);
              });
            }}
            color="secondary"
          >
            {isUploading ? <CircularProgress /> : "Delete"}
          </Button>
          <Typography
            className={classes.secondaryHeading}
            style={{ fontStyle: "italic" }}
          >
            added by : {props.userEmail}
          </Typography>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default withStyles(CollapsableCard, useStyles);
