import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Container } from "@mui/material";
import DropBox from "./DropBox";
import { useSnackbar } from "notistack";
import { withStyles } from "tss-react/mui";
import axios from "axios";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ClubsEventsUploader = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { classes } = useStyles();
  const [files, setFiles] = useState([]);
  const uploadFile = (file, type) => {
    const data = new FormData();
    data.append("file", file);
    let url = "/api/file/clubEvents/";
    axios
      .post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        const key = enqueueSnackbar("File - " + file.name + " uploaded", {
          variant: "success",
        });
      })
      .catch((e) => {
        if (e.response?.data) {
          enqueueSnackbar(e.response.data, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        }
      });
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadFile(file, type);
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <DropBox
        type="main"
        uploadedFiles={files}
        handleDropFile={handleDropFile}
      />
    </Container>
  );
};
export default withStyles(ClubsEventsUploader, useStyles);
