import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import Casino from "@mui/icons-material/Casino";

const useStyles = makeStyles()((theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    display: "flex",
    position: "relative",
    width: theme.spacing(8),
  },
  icon: {
    width: 35,
    height: 35,
  },
  listItem: {
    display: "flex",
    justifyContent: "center",
  },
}));

const sideList = [
  {
    name: "diceValidate",
    title: "Dice Validate",
    icon: (
      <Casino
        style={{
          color: "inherit",
        }}
      />
    ),
  },
];

export default function ValidatorSideBar(props) {
  const { classes } = useStyles();
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbarIcon}>
        <img className={classes.icon} alt="" src="icon.png"></img>
      </div>
      <Divider light={false} />
      <List>
        <div>
          {sideList.map((option) => (
            <Tooltip
              title={option.title}
              key={option.name}
              aria-label={option.name}
              placement="right"
            >
              <ListItem
                button
                className={classes.listItem}
                style={{
                  color:
                    props.currentView === option.name ? "#5da6d2" : "#cac9c9",
                }}
                onClick={() => {
                  props.setCurrentView(option.name);
                }}
              >
                {option.icon}
              </ListItem>
            </Tooltip>
          ))}
        </div>
      </List>
    </Drawer>
  );
}

ValidatorSideBar.propTypes = {
  children: PropTypes.node,
};
