import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { withStyles } from "tss-react/mui";
import { Component } from "react";
import { PropTypes } from "prop-types";
import ValidatorSideBar from "../components/ValidatorSideBar";
import axios from "axios";
import { getUserEmail } from "../utils/tokenUtils";
import DiceValidate from "./DiceValidate";

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of MemberExpression.
const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  content: {
    backgroundColor: "#efefef",
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
});

class ValidatorDashboard extends Component {
  state = {
    user: {},
    currentView: "diceValidate",
    currentLiveServer: 0,
    userLoading: false,
    liveLoading: false,
    liveInfo: {},
    eventDiceID: "0",
    cosmeticDiceID: "0",
    rewardBoxDiceID: "0",
    tokenID: "0",
    frameID: "0",
    avatarID: "0",
    nextVersion: 0,
    cohortData: {},
    cohortLoading: true,
  };

  componentDidMount = () => {
    this.getLiveInfo(this.state.currentLiveServer);
    this.getCohortData(1);
    this.getUserInfo();
    this.getCurrentDiceID();
    this.getCurrentTokenID();
    this.getCurrentFrameID();
    this.getCurrentAvatarID();
  };

  getCohortData = (server) => {
    this.setState({ cohortLoading: true });
    axios
      .get("/api/getCohort/server/" + server)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          this.setState({ cohortLoading: false });
        } else {
          this.setState({
            cohortData: data["CD"]["cohort_config"],
            cohortLoading: false,
          });
        }
      });
  };

  getUserInfo = () => {
    this.setState({ userLoading: true });
    var email = getUserEmail();
    axios
      .get("/api/user/email/" + email)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          this.setState({ userLoading: false });
        } else {
          this.setState({ user: data, userLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ userLoading: false });
      });
  };

  getLiveInfo = (server) => {
    this.setState({ liveLoading: true });
    axios
      .get("/api/liveinfo/server/" + server)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          this.setState({ liveLoading: false });
        } else {
          this.setState({
            liveInfo: data,
            nextVersion: data["NV"],
            liveLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ liveLoading: false });
      });
  };

  getCurrentDiceID = (server) => {
    axios
      .get("/api/diceinfo")
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
        } else {
          var diceIDs = data.payload.split("|");
          this.setState({ eventDiceID: diceIDs[0] });
          this.setState({ cosmeticDiceID: diceIDs[1] });
          this.setState({ rewardBoxDiceID: diceIDs[2] });
        }
      })
      .catch((err) => { });
  };

  getCurrentTokenID = (server) => {
    axios
      .get("/api/tokeninfo")
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
        } else {
          this.setState({ tokenID: data.payload });
        }
      })
      .catch((err) => { });
  };

  getCurrentFrameID = (server) => {
    axios
      .get("/api/frameinfo")
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
        } else {
          this.setState({ frameID: data.payload });
        }
      })
      .catch((err) => { });
  };

  getCurrentAvatarID = (server) => {
    axios
      .get("/api/avatarinfo")
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
        } else {
          this.setState({ avatarID: data.payload });
        }
      })
      .catch((err) => { });
  };

  addEventDraft = (eventName, eventType) => {
    this.setState({ userLoading: true });
    axios
      .get("/api/addEvent/name/" + eventName + "/type/" + eventType)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          this.setState({ userLoading: false });
        } else {
          this.setState({ user: data, userLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ userLoading: false });
      });
  };

  removeEventDraft = (eventName) => {
    this.setState({ userLoading: true });
    axios
      .delete("/api/addEvent/name/" + eventName)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          this.setState({ userLoading: false });
        } else {
          this.setState({ user: data, userLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ userLoading: false });
      });
  };

  setUserData = (userData) => {
    this.setState({ user: userData });
  };

  addFile = (eventID, type, file) => {
    let state = Object.assign({}, this.state);
    var index = 0;
    for (var eve of state.user.EventDrafts) {
      if (eve.ID === eventID) {
        break;
      }
      index++;
    }
    if (type === "main") {
      state.user.EventDrafts[index].MainFiles.push(file);
    } else {
      state.user.EventDrafts[index].BackgroundFiles.push(file);
    }
    this.setState(state);
  };

  updateEvent = (event) => {
    let state = Object.assign({}, this.state);
    var index = 0;
    for (var eve of state.user.EventDrafts) {
      if (eve.ID === event.ID) {
        break;
      }
      index++;
    }
    state.user.EventDrafts[index].EventData = event.EventData;
    this.setState(state);
  };

  updateNotif = (fileName) => {
    let state = Object.assign({}, this.state);
    state.user.Notifications.FileName = fileName;
    this.setState(state);
  };

  updateUserRewards = (fileName) => {
    let state = Object.assign({}, this.state);
    state.user.UserRewards.FileName = fileName;
    this.setState(state);
  };

  getCurrentView = () => {
    switch (this.state.currentView) {
      case "diceValidate":
        return (
          <DiceValidate
            eventDiceID={parseInt(this.state.eventDiceID) + 1}
            cosmeticDiceID={parseInt(this.state.cosmeticDiceID) + 1}
            rewardBoxDiceID={parseInt(this.state.rewardBoxDiceID) + 1}
          />
        );
      default:
        return "";
    }
  };

  handleLiveInfoChange = (event) => {
    this.setState({ currentLiveServer: event.target.value });
    this.getLiveInfo(event.target.value);
  };

  setCurrentView = (view) => {
    this.setState({ currentView: view });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <ValidatorSideBar
          setCurrentView={this.setCurrentView}
          currentView={this.state.currentView}
        />
        <main className={classes.content}>{this.getCurrentView()}</main>
      </div>
    );
  }
}

ValidatorDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(ValidatorDashboard, useStyles);
