export const ConfigListPS = [
    {
        label: "Base",
        value: "CON"
    },
    {
        label: "Daily Deal",
        value: "DDC"
    },
    {
        label: "App",
        value: "APP"
    },
    {
        label: "Card Collection",
        value: "CCC"
    },
    {
        label: "Avatar",
        value: "AVC"
    },
    {
        label: "Dice",
        value: "DCE"
    },
    {
        label: "Board",
        value: "BDC"
    },
    {
        label: "Frame",
        value: "FAC"
    },
    {
        label: "Token",
        value: "TKC"
    },
    {
        label: "Old Season",
        value: "OSC"
    },
    {
        label: "Fortune Room",
        value: "FRC"
    },
    {
        label: "Festival",
        value: "FSC"
    },
    {
        label: "Level",
        value: "LEC"
    },
    {
        label: "Ludo Vip",
        value: "LVC"
    },
    {
        label: "New Dice",
        value: "NDC"
    },
    {
        label: "New Token",
        value: "NTC"
    },
    {
        label: "New Frame",
        value: "NFC"
    },
];