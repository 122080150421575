import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import {
  Container,
  FormControl,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { PropTypes } from "prop-types";
import { useSnackbar } from "notistack";
import Wrapper from "../components/Wrapper";
import axios from "axios";
import FrameUploader from "../components/FrameUploader";
import InfoIcon from "@mui/icons-material/Info";
import { EnvVars } from "../common/environment";
import { playEventCompletionSound } from "../utils/sound";

const theme = createTheme({
  typography: {
    toolTipHeader: {
      color: "orange",
    },
    toolTipMessage: {
      color: "white",
    },
  },
});

function getToolTipLine(left, right) {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography variant="toolTipHeader">{left}</Typography>
        <Typography variant="toolTipMessage">{right}</Typography>
        <br></br>
      </ThemeProvider>
    </div>
  );
}

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const FrameUpload = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [currentServer, setCurrentServer] = useState(0);
  const [files, setFiles] = useState([]);
  const [FrameInfos, setFrameInfos] = useState([]);
  const [frameUploaders, setFrameUploaders] = useState([]);
  const [addFrameEnabled, setAddFrameEnabled] = useState(false);

  const handleServerChange = (event) => {
    setCurrentServer(event.target.value);
  };

  const publishFrame = () => {
    if (!addFrameEnabled) {
      const key = enqueueSnackbar("Enter all details", {
        variant: "error",
      });
      return;
    }

    setLoading(true);
    let url = "/api/uploadFrame/dev/" + currentServer;
    axios
      .post(url, FrameInfos)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Frame Publish Failed", {
            variant: "error",
          });
          playEventCompletionSound(false);
        } else {
          const key = enqueueSnackbar("Frame Publish Successful", {
            variant: "success",
          });
          playEventCompletionSound(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Frame Publish Failed", {
          variant: "error",
        });
        playEventCompletionSound(false);
        setLoading(false);
      });
  };

  const setEnableAddFrame = (isEnabled) => {
    setAddFrameEnabled(isEnabled);
  };

  const addFrameInfos = (frameInfo) => {
    var newFrameInfo = true;
    if (FrameInfos.length > 0) {
      FrameInfos.map((v, i) => {
        if (frameInfo.FrameID == v.FrameID) {
          newFrameInfo = false;
        }
      });
    }

    if (newFrameInfo) {
      setFrameInfos((current) => [...current, frameInfo]);
    }
  };

  const addFrames = () => {
    setAddFrameEnabled(false);
    setFrameUploaders((current) => [
      ...current,
      <FrameUploader
        props={props}
        addFrameInfo={addFrameInfos}
        setEnableAddFrame={setEnableAddFrame}
        frameID={props.frameID}
      />,
    ]);
  };

  const getFrameUploaders = () => {
    var frameUploader = [];

    if (frameUploaders.length > 0) {
      frameUploader = frameUploaders;
    } else {
      let frameObj = (
        <FrameUploader
          props={props}
          addFrameInfo={addFrameInfos}
          setEnableAddFrame={setEnableAddFrame}
          frameID={props.frameID}
        />
      );
      frameUploader.push(frameObj);
      setFrameUploaders((current) => [...current, frameObj]);
    }

    return frameUploader;
  };

  const { classes } = props;
  return (
    <div className={classes.root}>
      <Wrapper heading="Frame Upload" setIsLoggedIn={props.setIsLoggedIn}>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.header}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={addFrames}
              disabled={!addFrameEnabled || loading}
            >
              Add Frame
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Frame Upload File Info
                  </Typography>
                  {getToolTipLine("Filename:", "	frame_(ID Number).png")}
                  {getToolTipLine("Dimensions:", "	270 x 270 approx")}
                  {getToolTipLine("Number:", "	1")}
                </React.Fragment>
              }
            >
              <InfoIcon sx={{ fontSize: 40 }}></InfoIcon>
            </Tooltip>
            <div style={{ flexGrow: 1 }}></div>

            {EnvVars.EnvSelectorDisabled ? (
              <></>
            ) : (
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="server-select"
                  id="server-select"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={currentServer}
                  onChange={handleServerChange}
                >
                  <MenuItem value={0}>Dev</MenuItem>
                  <MenuItem value={1}>Production</MenuItem>
                </Select>
              </FormControl>
            )}

            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={publishFrame}
              disabled={loading}
            >
              Publish
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </div>
          {getFrameUploaders()}
        </Container>
      </Wrapper>
    </div>
  );
};

FrameUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(FrameUpload, useStyles);
